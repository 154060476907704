import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import ReactMarkdown from "react-markdown";
import Brands from '../../components/Brands/Brands';

import { useLocation, useHistory } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Helmet } from 'react-helmet';


const RealEstateSales = () => {

  const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const { i18n, t } = useTranslation();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectOption, setSelectOption] = useState('');
  const [subject, setSubject] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const selectRef = useRef(null); 

  const handleScroll = () => {
    if (menuOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isVisible) {
        setMenuOpen(false); // Close the menu if it goes out of view
      }
    }
  };

  useEffect(() => {
    if (menuOpen) {
      // Add scroll event listener when the menu is open
      window.addEventListener("scroll", handleScroll);
    } else {
      // Remove scroll event listener when the menu is closed
      window.removeEventListener("scroll", handleScroll);
    }

    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuOpen]);
    // Handle form submission
    const [error, setError] = useState({ field: "", message: "" });
    const [snackbarOpen, setSnackbarOpen] = useState(false);
  const link =
  i18n.language === 'de'
    ? 'https://lp.sae-mallorca.com/'
    : 'https://lp.sae-mallorca.com/en/home/';

  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/real-estate-sale?locale=${lang}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data.attributes);
    
        setContent(response.data.data.attributes.content_field);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset error state before validation
    setError({ field: "", message: "" });

    // Validation checks
    if (!name) {
      setError({ field: "name", message: t("nameRequired") });
    } else if (!email) {
      setError({ field: "email", message: t("emailRequired") });
    } else if (!validateEmail(email)) {
      setError({
        field: "email",
        message: t("invalidEmail"),
      });
    } else if (!phone) {
      setError({ field: "phone", message: t("phoneRequired") });
    } else if (!selectOption) {
      setError({ field: "selectOption", message: t("requestTypeRequired") });
    } else if (!subject) {
      setError({ field: "subject", message: t("subjectRequired") });
    } else if (!agreed) {
      setError({
        field: "agreed",
        message: t("privacyPolicyAgreement"),
      });
    } else {
      // console.log('Name:', name);
      // console.log('Email:', email);
      // console.log('Phone:', phone);
      // console.log('Request Type:', selectOption);
      // console.log('Subject:', subject);
      // console.log('Agreed to Privacy Policy:', agreed);
      setError({ field: "", message: "" });
      submitBookings(name, email, phone, subject, selectOption)
    }
    if (error.field) {
      setSnackbarOpen(true);
    }
  };
  const tokens = "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5"
  const submitBookings = async (
    name,
    email,
    phone,
    subject,
    selectOption,
  ) => {
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/messages";
    let reqData = {
        "data": {
          name: name,
          email: email,
          phone: phone,
          message: subject,
          reason: selectOption
        }
  };
    try {
      const response = await axios.post(API_URL, reqData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
      });
      // console.log("RESPONSE ====>", response);
      if (response.status == 200) {
        toast.success(t("thankYouContact"));
        history.push("/");
      } else {
        toast.error(t("errorOccurred"));
      }
    } catch (error) {
      console.error("Error fetching data:", error.response);
      toast.error(t("errorOccurred"));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
       <Helmet>
        <meta itemprop="inLanguage" content="de" />
        <meta
          name="description"
          itemprop="description"
          content={content}
        />

        <link
          rel="canonical"
          href={`https://www.sae-mallorca.com/verkauf`}
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/verkauf`}
          hreflang="de"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/verkauf`}
          hreflang="en"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/verkauf`}
          hreflang="es"
        />
        {/* <link rel="icon" href={`${img}`} /> */}
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('propertySale')} />
        {/* <meta property="og:image" content={`${img}`} /> */}
        <meta property="og:description" content={content} />
        <meta
          property="og:url"
          content={`https://www.sae-mallorca.com/verkauf`}
        />
        <meta
          property="og:site_name"
          content="Immobilien Mallorca kaufen |S.A.E Real Estate Mallorca"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={content} />
        <meta name="twitter:title" content={t('propertySale')} />
      </Helmet>
      <div className="innercontent_wrap">
        {/* <Brands/> */}
        <div className="innercontent">
          <div className="auto cm_2">
          <ReactMarkdown>{content}</ReactMarkdown>
        
            <div className="content">
                <a href={link}><strong>→ {t('learnMore')}</strong></a>
            </div>
          </div>
        </div>
        <div className="cm_form contfrom_form">
      <div className="auto">
        <div className="heading">{t("contactForm")}</div>
        <form name="form_free" className="c_form contactfrom" onSubmit={handleSubmit}>
          <div className="c_form_string c_field">
            <div className="material-textfield">
              <input
                placeholder={t("namePlaceholder")}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label>{t("nameLabel")}</label>
            </div>
            <div className="inprow_opr2">
              <div className="material-textfield">
                <input
                  placeholder={t("emailPlaceholder")}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label>{t("emailLabel")}</label>
              </div>
              <div className="material-textfield">
                <input
                  placeholder={t("phonePlaceholder")}
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label>{t("phoneLabel")}</label>
              </div>
            </div>
            <div className="inprow_opr1">
              <div className="material-textfield2">
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{width:'500px'}}>
                      {t("requestTypeLabel")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      displayEmpty
                      value={selectOption}
                      label={t("requestTypeLabel")}
                      renderValue={(selected) => {
                        // console.log(selected);
                        if (selectOption === '') {
                          return <em style={{fontSize:"0.9rem", color:'#676767'}}>{t("selectInquiry")}</em>;
                        }
            
                        return selected
                      }}
                      open={menuOpen} // Control the menu's open state
                      onClose={() => setMenuOpen(false)} // Close menu on select close
                      onOpen={() => setMenuOpen(true)} // Open menu on select open
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null, // Ensures proper menu positioning
                        disableScrollLock: true, // Prevents scroll lock on mobile
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          //   width: "auto", // Ensures full-width menu
                         
                          },
                        },
                      }}
                      ref={selectRef} 
                      inputProps={{ 'aria-label': 'Without label' }}
                    
                      onChange={(e) => setSelectOption(e.target.value)}
                    >
                      <MenuItem value={t("selectOptions.sellProperty")}>
                        {t("selectOptions.sellProperty")}
                      </MenuItem>
                      <MenuItem value={t("selectOptions.buyProperty")}>
                        {t("selectOptions.buyProperty")}
                      </MenuItem>
                      <MenuItem value={t("selectOptions.generalInquiry")}>
                        {t("selectOptions.generalInquiry")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </div>
            <div className="inprow_opr1">
              <div className="material-textfield">
                <textarea
                  id="textarea_subject"
                  rows="6"
                  cols="40"
                  placeholder={t("subjectPlaceholder")}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                ></textarea>
              </div>
            </div>

            <div className="c_form_checkbox c_field">
              <label className="wrapPrivacyPolicy" data-id="agreed">
                <input
                  type="checkbox"
                  id="checkbox_agreed"
                  data-id="agreed"
                  checked={agreed}
                  onChange={() => setAgreed(!agreed)}
                />
                <span>{t("privacyPolicyText")}{" "}
                <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
                  {t("privacyPolicyLinkText")}
                </a>
                *
                </span>
              </label>
            </div>
          </div>

          <div className="row two narrow end actions">
            <button type="submit" className="button index_0 ">
              {t("submitButton")}
            </button>
          </div>
         
        </form>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {error.message}
        </Alert>
      </Snackbar>
    </div>
      </div>
    </>
  );
};

export default RealEstateSales;
