import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PropertySearchItems from "../../components/PropertySearchItems/PropertySearchItems";
import { useLocation, useHistory } from "react-router-dom";
import PropertyFilter from "./PropertyFilter";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import loadingAnimation from "../../assets/animation/loadingAnimation.json";
import SearchIcon from "@mui/icons-material/Search";
// import loadingAnimation from "../../assets/animation/houseAnimation.json";
// import loadingAnimation from "../../assets/animation/propertyAnimation.json";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { Audio } from "react-loader-spinner";
import useStore from "../../Store/useStore";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import { Popper, Paper, ClickAwayListener, TextField } from "@mui/material";

import "./MySlider.css";
import { base_url, property_base_url } from "../../api/const";
import { Helmet } from "react-helmet";
const PropertySearch = () => {
  useEffect(() => {
    // Scroll to the top when this component is mounted
    window.scrollTo(0, 0);
  }, []);
  const actionRef = React.useRef(null);
  const [data, setData] = useState([]); // Default value can be null or an empty array/object depending on the data structure
  // const [loading, setLoading] = useState(false);
  // const [loadingGer, setLoadingGer] = useState(false);
  // const [loadingSpa, setLoadingSpa] = useState(false);
  const [dataENG, setDataENG] = useState([]);
  const [dataESP, setDataESP] = useState([]);
  const [dataGER, setDataGER] = useState([]);
  const [allData, setAllData] = useState([]);
  const [espProp, setEspProp] = useState([]);
  const [engProp, setEngProp] = useState([]);
  const [gerProp, setGgerProp] = useState([]);
  const [esLoading, setEsLoading] = useState(false);
  const [deLoading, setDeLoading] = useState(false);
  const [enLoading, setEnLoading] = useState(false);
  const [loading, setLoading] = useState({ en: false, es: false, de: false });
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);
  const mainToken =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  const { t, i18n } = useTranslation();
  const fetchPropertyData = async (lang) => {
    setLoading((prev) => ({ ...prev, [lang]: true }));
    try {
      const response = await axios.get(
        `${property_base_url}properties/${lang}.json`,
        {
          headers: { Authorization: `Bearer ${mainToken}` },
        }
      );
      const data = response.data;
      setAllData(response.data);
      if (lang === "en") setDataENG(data);
      if (lang === "es") setDataESP(data);
      if (lang === "de") setDataGER(data);
      if (i18n.language.startsWith(lang)) setData(data);
      if (
        filters.minPrice !== "" ||
        filters.maxPrice !== "" ||
        filters.selectedLocations.length > 0 ||
        filters.propertyType.length > 0 ||
        filters.noOfBed !== "" ||
        filters.noOfBath !== "" ||
        filters.propArea !== ""
      ) {
        // console.log("Filter true", filters);

        setMaxPrice(filters.maxPrice);
        setMinPrice(filters.minPrice);
        setSelectedLocations(filters.selectedLocations);
        setPropertyType(filters.propertyType);
        setNoOfBed(filters.noOfBed);
        setNoOfBath(filters.noOfBath);
        let ref_no = filters.refNo === undefined ? "" : filters.refNo;

        filterData(
          filters.maxPrice,
          filters.minPrice,
          filters.selectedLocations,
          filters.propertyType,
          filters.noOfBed,
          ref_no,
          filters.noOfBath,
          filters.propArea,
          [],
          response.data,
          true
        );
      } // Update main data based on language
    } catch (error) {
      console.error(`Error fetching ${lang} data:`, error.message);
    } finally {
      setLoading((prev) => ({ ...prev, [lang]: false }));
    }
  };

  useEffect(() => {
    // Detect if we are navigating back to this component

    // Only call fetchPropertyData when navigating back to this route
    fetchPropertyData("en");
    fetchPropertyData("es");
    fetchPropertyData("de");

    // Update previous location after handling
  }, []);

  useEffect(() => {
    // Update main data based on selected language
    if (i18n.language.startsWith("en")) {
      // console.log(filters);
      let ref_no = filters.refNo === undefined ? "" : filters.refNo;
      // console.log(ref_no);
      if (
        ref_no !== "" ||
        filters.minPrice !== "" ||
        filters.maxPrice !== "" ||
        filters.selectedLocations.length !== 0 ||
        filters.propertyType.length > 0 ||
        filters.noOfBed !== "" ||
        filters.noOfBath !== "" ||
        filters.propArea !== ""
      ) {
        filterData(
          filters.maxPrice,
          filters.minPrice,
          filters.selectedLocations,
          filters.propertyType,
          filters.noOfBed,
          filters.refNo,
          filters.noOfBath,
          filters.propArea,
          [],
          dataENG
        );
      } else {
        setData(dataENG);
        setAllData(dataENG);
      }
    }

    if (i18n.language.startsWith("es")) {
      let ref_no = filters.refNo === undefined ? "" : filters.refNo;
      if (
        ref_no !== "" ||
        filters.minPrice !== "" ||
        filters.maxPrice !== "" ||
        filters.selectedLocations.length !== 0 ||
        filters.propertyType.length > 0 ||
        filters.noOfBed !== "" ||
        filters.noOfBath !== "" ||
        filters.propArea !== ""
      ) {
        filterData(
          filters.maxPrice,
          filters.minPrice,
          filters.selectedLocations,
          filters.propertyType,
          filters.noOfBed,
          filters.refNo,
          filters.noOfBath,
          filters.propArea,
          [],
          dataESP
        );
      } else {
        setData(dataESP);
        setAllData(dataESP);
      }
    }

    if (i18n.language.startsWith("de")) {
      let ref_no = filters.refNo == undefined ? "" : filters.refNo;
      if (
        ref_no !== "" ||
        filters.minPrice !== "" ||
        filters.maxPrice !== "" ||
        filters.selectedLocations.length !== 0 ||
        filters.propertyType.length > 0 ||
        filters.noOfBed !== "" ||
        filters.noOfBath !== "" ||
        filters.propArea !== ""
      ) {
        filterData(
          filters.maxPrice,
          filters.minPrice,
          filters.selectedLocations,
          filters.propertyType,
          filters.noOfBed,
          filters.refNo,
          filters.noOfBath,
          filters.propArea,
          [],
          dataGER
        );
      } else {
        setData(dataGER);
        setAllData(dataGER);
      }
    }
  }, [i18n.language, dataENG, dataESP, dataGER]);

  const history = useHistory();
  // const [page, setPage] = React.useState(1);
  const [priceSort, setPriceSort] = React.useState("");
  const [timeSort, setTimeSort] = React.useState("");
  const [selectOption, setSelectOption] = React.useState("");
  // const [estates, setEstates] = useState([]);
  const [estatesWithImages, setEstatesWithImages] = useState([]);
  // const [data, setData] = useState([]);
  // const [allData, setAllData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [visibleComponent, setVisibleComponent] = useState(null);

  // const data = useStore((state) => state.data);
  // const setData = useStore((state) => state.setData);

  // const loading = useStore((state) => state.loading);
  // const loadingGer = useStore((state) => state.loadingGer);
  // const loadingSpa = useStore((state) => state.loadingSpa);

  // const dataENG = useStore((state) => state.dataENG);

  // const dataESP = useStore((state) => state.dataESP);

  // const dataGER = useStore((state) => state.dataGER);

  const selectLanguage = useStore((state) => state.selectLanguage);
  const page = useStore((state) => state.page);
  const setPage = useStore((state) => state.setPage);
  const filters = useStore((state) => state.filters);
  const setFilters = useStore((state) => state.setFilters);
  const clearFilters = useStore((state) => state.clearFilters);

  const [allFilterData, setAllFilterData] = useState(null);
  // const setSelectLanguage = useStore((state) => state.setSelectLanguage);
  // // console.log(data)

  // const [data, setData] = useState(
  //   selectLanguage === "de"
  //     ? dataGER
  //     : selectLanguage === "es"
  //     ? dataESP
  //     : dataENG
  // );

  const [espData, setEspData] = useState([]);
  const [engData, setEngData] = useState([]);
  const [gerData, setGerData] = useState([]);

  const [espEstates, setEspEstates] = useState([]);
  const [engEstates, setEngEstates] = useState([]);
  const [gerEstates, setGerEstates] = useState([]);

  const [estates, setEstates] = useState(
    selectLanguage === "de"
      ? dataGER
      : selectLanguage === "es"
      ? dataESP
      : dataENG
  );

  useEffect(() => {
    setEspEstates(dataESP);
    setEngEstates(dataENG);
    setGerEstates(dataGER);
    // console.log("++");
  }, [dataGER, dataESP, dataENG]);
  // useEffect(() => {
  //   // console.log(selectLanguage, "select language =+_+_+_+_+___________++++");
  //   if (i18n.language.startsWith("de") && dataGER.length > 0) {
  //     // console.log("GER DATA      +++++++++++++++++++++++++", gerData)
  //     gerData.length == 0 || dataGER.length == gerData.length
  //       ? setData(dataGER)
  //       : setData(gerData);
  //     gerData.length == 0 ? setGerData(dataGER) : setGerData(gerData);
  //     setGerEstates(dataGER);
  //     setAllData(dataGER);
  //     // console.log("1-1", gerData.length, dataGER.length, dataGER, gerData);
  //   } else if (i18n.language.startsWith("es") && dataESP.length > 0) {
  //     espData.length == 0 || espData.length == dataESP.length
  //       ? setData(dataESP)
  //       : setData(espData);
  //     espData.length == 0 ? setEspData(dataESP) : setEspData(espData);
  //     setEspEstates(dataESP);
  //     setAllData(dataESP);
  //     // console.log("1-2", espData.length, dataESP.length, dataESP, espData);
  //   } else if (i18n.language.startsWith("en") && dataENG.length > 0) {
  //     // console.log("----------------------", i18n.language.startsWith("en"));
  //     engData.length == 0 || engData.length == dataENG.length
  //       ? setData(dataENG)
  //       : setData(engData);
  //     engData.length == 0 ? setEngData(dataENG) : setEngData(engData);
  //     setAllData(dataENG);
  //     setEngEstates(dataENG);
  //     // console.log(
  //       "1-3",
  //       engData.length,
  //       dataENG.length,
  //       dataENG,
  //       engData,
  //       engData.length == 0 || engData.length == dataENG.length
  //     );
  //   } else {
  //     setData(dataGER);
  //     setAllData(dataGER);
  //     // console.log("1-4", dataGER);
  //   }
  // }, [dataGER , dataESP, dataENG, i18n.language]);

  const {
    refNumber,
    maximumPrice,
    minimumPrice,
    filteredLocations,
    typeOfProperty,
    numberOfBed,
  } = location.state || {};
  // console.log("--------- ------------", location.state);

  const [refNo, setRefNo] = useState(refNumber);
  const [maxPrice, setMaxPrice] = useState(filters.maxPrice || "");
  const [minPrice, setMinPrice] = useState(filters.minPrice || "");
  const [selectedLocations, setSelectedLocations] = useState(
    filters.selectedLocations || []
  );
  const [addedLocation, setAddedLocation] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [locationAdded, setLocationAdded] = useState(false);
  const [propertyType, setPropertyType] = useState(filters.propertyType || []);
  const [noOfBed, setNoOfBed] = useState(filters.noOfBed || "");
  const [noOfBath, setNoOfBath] = useState(filters.noOfBath);
  const [propArea, setPropArea] = useState(filters.propArea);
  const [showFilters, setShowFilters] = useState(null);
  useEffect(() => {
    // // console.log(location);
    if (location.state && location?.state?.refNumber) {
      let ref = location.state.refNumber;
      filterData(
        "",
        "",
        [],
        [],
        "",
        location?.state?.refNumber,
        "",
        "",
        [],
        data
      );
      setRefNo(location.state.refNumber);
      // console.log("1", location.state.refNumber);
      const { refNumber, ...restState } = location.state;
      // history.replace({
      //   ...location,
      //   state: restState,
      // });
      setShowFilters(true);
    }
    if (location.state && location?.state?.typeOfProperty) {
      // console.log(location.state.typeOfProperty);
      // // console.log(data);
      // setShowFilters(true);
      setPropertyType(location.state.typeOfProperty);
      // setAllFilterData({ propertyType: propertyType});
      filterData(
        "",
        "",
        [],
        location.state.typeOfProperty,
        "",
        "",
        "",
        "",
        [],
        data,
        true
      );
      // console.log("2");
      history.replace({
        // pathname: location.pathname,
        state: null,
      });
    }
    if (location.state && location?.state?.filteredLocations) {
      // // console.log(location.state.filteredLocations);
      // setShowFilters(true);
      setSelectedLocations(location.state.filteredLocations);
      filterData(
        "",
        "",
        location.state.filteredLocations,
        [],
        "",
        "",
        "",
        "",
        [],
        data,
        true
      );
      // console.log("3", location.state.filteredLocations);
      history.replace({
        // pathname: location.pathname,
        state: null,
      });
    }
    if (location.state && location?.state?.minimumPrice) {
      // // console.log(location.state.filteredLocations);
      // setShowFilters(true);
      setMinPrice(location.state.minimumPrice);
      filterData(
        "",
        location.state.minimumPrice,
        [],
        [],
        "",
        "",
        "",
        "",
        [],
        data,
        true
      );
      history.replace({
        // pathname: location.pathname,
        state: null,
      });
    }
    if (location.state && location?.state?.maximumPrice) {
      // // console.log(location.state.filteredLocations);
      // setShowFilters(true);
      setMaxPrice(location.state.maximumPrice);
      filterData(
        location.state.maximumPrice,
        "",
        [],
        [],
        "",
        "",
        "",
        "",
        [],
        data,
        true
      );
      // console.log("3", location.state.filteredLocations);
      history.replace({
        // pathname: location.pathname,
        state: null,
      });
    }
    if (location.state && location?.state?.numberOfBed) {
      // // console.log(location.state.filteredLocations);
      // setShowFilters(true);
      setNoOfBed(location.state.numberOfBed);
      filterData(
        "",
        "",
        [],
        [],
        location.state.numberOfBed,
        "",
        "",
        "",
        [],
        data,
        true
      );
      // console.log("3", location.state.filteredLocations);
      // history.replace({
      //   // pathname: location.pathname,
      //   state: null,
      // });
    }
    // console.log(filters);

    //     if (
    //       filters.minPrice !== '' ||
    //       filters.maxPrice !== '' ||
    //       filters.selectedLocations.length > 0 ||
    //       filters.propertyType.length > 0 ||
    //       filters.noOfBed !== '' ||
    //       filters.noOfBath !== '' ||
    //       filters.propArea !== '') {
    //       // console.log("Filter true", filters );

    //       setMaxPrice(filters.maxPrice);
    //       setMinPrice(filters.minPrice);
    //       setSelectedLocations(filters.selectedLocations);
    //       setPropertyType(filters.propertyType);
    //       setNoOfBed(filters.noOfBed);
    //       setNoOfBath(filters.noOfBath)

    //      filterData(
    //           filters.maxPrice,
    //             filters.minPrice,
    //             filters.selectedLocations,
    //             filters.propertyType,
    //             filters.noOfBed,
    //             filters.refNo,
    //             filters.noOfBath,
    //             filters.propArea,
    //             [],
    //             data,
    //             true
    //           )
    //           // console.log(data)
    // }
  }, [
    dataGER,
    dataESP,
    dataENG,
    location.state?.refNumber,
    location.state?.typeOfProperty,
    location.state?.filteredLocations,
  ]);

  // useEffect(() => {
  //   // console.log(filters)
  //   if (
  //     filters.minPrice !== '' ||
  //     filters.maxPrice !== '' ||
  //     filters.selectedLocations.length > 0 ||
  //     filters.propertyType.length > 0 ||
  //     filters.noOfBed !== '' ||
  //     filters.noOfBath !== '' ||
  //     filters.propArea !== '') {
  //     // console.log("Filter true" );
  //     // // console.log(
  //     //   " ------------------------ STATE --------------------",
  //     //   maximumPrice,
  //     //   minimumPrice,
  //     //   filteredLocations,
  //     //   typeOfProperty,
  //     //   noOfBed,
  //     //   location?.state?.refNumber
  //     // );
  //     filterData(
  //         filters.maxPrice,
  //           filters.minPrice,
  //           filters.selectedLocations,
  //           filters.propertyType,
  //           filters.noOfBed,
  //           filters.refNo,
  //           filters.noOfBath,
  //           filters.propArea,
  //           [],
  //           data,
  //           true
  //         )

  //     // location.state.numberOfBed === '' || location.state.numberOfBed == undefined ? setNoOfBed('') : setNoOfBed(noOfBed)
  //   } else {
  //     // console.log("here11", filters.noOfBath);
  //     let DATA = i18n.language.startsWith("en") ? dataENG : i18n.language.startsWith("de") ? dataGER : i18n.language.startsWith("es") ? dataESP : dataGER
  //     setData(DATA);
  //     // console.log(filters)
  //     filters.refNo  &&
  //     filters.minPrice== '' &&
  //     filters.maxPrice === '' &&
  //     filters.selectedLocations.length == 0 &&
  //     filters.propertyType.length == 0 &&
  //     filters.noOfBed === '' &&
  //     filters.noOfBath === '' &&
  //     filters.propArea === '' ?

  //      setShowFilters(true) : setShowFilters(false)
  //   }
  // }, [dataGER , dataESP, dataENG]);

  useEffect(() => {
    // console.log(filters, location.state);
    if (location.state && Object.keys(location.state).length !== 0) {
      // console.log("4", location.state);
      // // console.log(
      //   " ------------------------ STATE --------------------",
      //   maximumPrice,
      //   minimumPrice,
      //   filteredLocations,
      //   typeOfProperty,
      //   noOfBed,
      //   location?.state?.refNumber
      // );
      location.state.minimumPrice === "" &&
      location.state.maximumPrice === "" &&
      location.state.filteredLocations.length == 0 &&
      location.state.typeOfProperty.length == 0 &&
      location.state.numberOfBed === "" &&
      location?.state?.refNumber === ""
        ? filterData(
            maximumPrice,
            minimumPrice,
            filteredLocations,
            typeOfProperty,
            noOfBed,
            location?.state?.refNumber,
            "",
            "",
            [],
            data,
            false
          )
        : filterData(
            location.state.maximumPrice,
            location.state.minimumPrice,
            filteredLocations,
            typeOfProperty,
            location.state.numberOfBed,
            location?.state?.refNumber,
            noOfBath,
            propArea,
            [],
            data,
            true
          );
      // location.state.numberOfBed === '' || location.state.numberOfBed == undefined ? setNoOfBed('') : setNoOfBed(noOfBed)
    }
    // else if (
    //   filters.minPrice !== '' ||
    //   filters.maxPrice !== '' ||
    //   filters.selectedLocations.length > 0 ||
    //   filters.propertyType.length > 0 ||
    //   filters.noOfBed !== '' ||
    //   filters.noOfBath !== '' ||
    //   filters.propArea !== '') {
    //   // console.log("Filter true" );

    //        filterData(
    //         filters.maxPrice,
    //           filters.minPrice,
    //           filters.selectedLocations,
    //           filters.propertyType,
    //           filters.noOfBed,
    //           filters.refNo,
    //           filters.noOfBath,
    //           filters.propArea,
    //           [],
    //           data,
    //           true
    //         )

    // }
    else {
      // if (
      //   filters.minPrice !== '' ||
      //   filters.maxPrice !== '' ||
      //   filters.selectedLocations.length > 0 ||
      //   filters.propertyType.length > 0 ||
      //   filters.noOfBed !== '' ||
      //   filters.noOfBath !== '' ||
      //   filters.propArea !== '') {
      //   // console.log("Filter true" );

      //   filterData(
      //       filters.maxPrice,
      //         filters.minPrice,
      //         filters.selectedLocations,
      //         filters.propertyType,
      //         filters.noOfBed,
      //         filters.refNo,
      //         filters.noOfBath,
      //         filters.propArea,
      //         [],
      //         data,
      //         true
      //       )

      // } else{
      // console.log("here11", filters.noOfBath);
      let DATA = i18n.language.startsWith("en")
        ? dataENG
        : i18n.language.startsWith("de")
        ? dataGER
        : i18n.language.startsWith("es")
        ? dataESP
        : dataGER;
      // // console.log(filters);
      // if (
      //   filters.refNo !== "" || filters.refNo != undefined ||
      //   filters.minPrice !== "" ||
      //   filters.maxPrice !== "" ||
      //   filters.selectedLocations.length !== 0 ||
      //   filters.propertyType.length > 0 ||
      //   filters.noOfBed !== "" ||
      //   filters.noOfBath !== "" ||
      //   filters.propArea !== ""
      // ) {
      // } else {
      //   setData(DATA);
      // }

      // // console.log(filters);

      // if (
      //   filters.refNo !== "" || filters.refNo != undefined ||
      //   filters.minPrice !== "" ||
      //   filters.maxPrice !== "" ||
      //   filters.selectedLocations.length > 0 ||
      //   filters.propertyType.length > 0 ||
      //   filters.noOfBed !== "" ||
      //   filters.noOfBath !== "" ||
      //   filters.propArea !== ""
      // ) {
      //   // console.log(filters.refNo !== "" || filters.refNo != undefined ||
      //     filters.minPrice !== "" ||
      //     filters.maxPrice !== "" ||
      //     filters.selectedLocations.length > 0 ||
      //     filters.propertyType.length > 0 ||
      //     filters.noOfBed !== "" ||
      //     filters.noOfBath !== "" ||
      //     filters.propArea !== "")
      //   setShowFilters(true);
      // } else {
      //   setShowFilters(false);
      // }

      // }
    }
  }, [location.state, dataGER, dataESP, dataENG]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const locations = [
    { id: "1", name: "Palma" },
    { id: "2", name: "Santa Ponça" },
    { id: "4", name: "Puig De Ros" },
    { id: "5", name: "Llucmajor" },
    { id: "6", name: "Sa Torre" },
    { id: "8", name: "Calvià / Santa Ponça" },
    { id: "11", name: "Palma de Mallorca" },
    { id: "14", name: "Llucmajor / s'Arenal" },
    { id: "18", name: "Esporles" },
    { id: "24", name: "Calvià / el Toro" },
    { id: "25", name: "Llucmajor / Son Verí Nou" },
    { id: "27", name: "Lloseta" },
    { id: "30", name: "Calvià / Costa d'en Blanes" },
    { id: "31", name: "Calvià" },
    { id: "41", name: "Calvià / Palmanova" },
    { id: "45", name: "Marratxí" },
    { id: "51", name: "Capdepera / Font de sa Cala" },
    { id: "57", name: "Badia Blava" },
    { id: "58", name: "S'Arenal" },
    { id: "64", name: "Ses palmeres" },
    { id: "65", name: "Can Pastilla" },
    { id: "67", name: "Cala Pi" },
    { id: "73", name: "Porto Cristo" },
    { id: "74", name: "Arenal" },
    { id: "75", name: "Tolleric" },
    { id: "77", name: "Calvià / Costa de la Calma" },
    { id: "81", name: "Cala Mendia" },
    { id: "82", name: "Bendinat" },
    { id: "84", name: "Son Verí Nou" },
    { id: "89", name: "Santa Margalida" },
    { id: "96", name: "Sant Agustin" },
    { id: "98", name: "santa ponsa" },
    { id: "101", name: "Arta" },
    { id: "107", name: "Cala Ratjada" },
    { id: "122", name: "Cala d'Or" },
    { id: "124", name: "Sant Joan" },
    { id: "126", name: "Platja de Palma" },
    { id: "134", name: "Paguera" },
    { id: "145", name: "Son Vida" },
    { id: "147", name: "Torrenova" },
    { id: "149", name: "Bunyola" },
    { id: "150", name: "Portixol" },
    { id: "155", name: "Son Sardina" },
    { id: "157", name: "Bellavista" },
    { id: "158", name: "Badia Gran" },
    { id: "161", name: "Can Picafort" },
    { id: "163", name: "Inca" },
    { id: "164", name: "Son Veri" },
    { id: "166", name: "Sa Coma" },
    { id: "169", name: "Sa Rapita" },
    { id: "171", name: "Costa d'en Blanes" },
    { id: "176", name: "Molinar" },
    { id: "178", name: "andratx" },
    { id: "181", name: "port-d-andratx" },
    { id: "184", name: "sol-de-mallorca" },
    { id: "187", name: "montuiri" },
    { id: "189", name: "es-pelats" },
    { id: "103", name: "Mallorca Süd" },
    { id: "104", name: "Mallorca Süd West" },
    { id: "105", name: "Mallorca Zentrum" },
    { id: "106", name: "Mallorca Palma" },
    { id: "113", name: "Mallorca Nord West" },
    { id: "114", name: "Mallorca Ost" },
    { id: "115", name: "Mallorca Nord" },
    { id: "116", name: "Mallorca West" },
    { id: "117", name: "Mallorca Süd Ost" },
  ];

  const locationMap = locations.reduce((acc, location) => {
    acc[location.id] = location.name;
    return acc;
  }, {});

  const handleShowComponent = (div) => {
    setVisibleComponent((prevDiv) => (prevDiv === div ? null : div));
  };

  const handleMaxPriceChange = useCallback((e) => {
    // Remove any non-digit characters and parse as integer
    const value = parseInt(e.target.value.replace(/\D/g, ""), 10) || "";
    setMaxPrice(value);
  }, []);

  const handleMinPriceChange = (event) => {
    // Remove any non-digit characters and parse as integer
    const value = parseInt(event.target.value.replace(/\D/g, ""), 10) || "";
    setMinPrice(value);
  };

  const handleNoOfBedChange = (event) => {
    const { value } = event.target;

    // Allow empty value for deletion
    if (value === "") {
      setNoOfBed("");
      return;
    }

    // Convert value to a number and ensure it's non-negative
    const numericValue = Number(value);
    if (numericValue >= 0 && !isNaN(numericValue)) {
      const values = parseInt(value.replace(/\D/g, ""), 10) || "";
      setNoOfBed(values); // Set the value as a string to keep leading zeros if needed
    }
    // setNoOfBed(event.target.value);
  };

  const handleNoOfBathChange = (event) => {
    // setVisibleComponent(null)
    const { value } = event.target;

    if (value === "") {
      setNoOfBath("");
      return;
    }

    const numericValue = Number(value);
    if (numericValue >= 0 && !isNaN(numericValue)) {
      const values = parseInt(value.replace(/\D/g, ""), 10) || "";
      setNoOfBath(values); // Set the value as a string to keep leading zeros if needed
    }
    // setNoOfBath(event.target.value);
  };
  const handlePropAreaChange = (event) => {
    const { value } = event.target;

    // Allow empty value for deletion
    if (value === "") {
      setPropArea("");
      return;
    }

    // Convert value to a number and ensure it's non-negative
    const numericValue = Number(value);
    if (numericValue >= 0 && !isNaN(numericValue)) {
      const values = parseInt(value.replace(/\D/g, ""), 10) || "";
      setPropArea(values); // Set the value as a string to keep leading zeros if needed
    }
  };

  const priceButtonClick = () => {
    if (maxPrice !== "" || minPrice !== "") {
      filterData(
        maxPrice,
        minPrice,
        selectedLocations,
        propertyType,
        noOfBed,
        refNo,
        noOfBath,
        propArea,
        addedLocation,
        true
      );
      // console.log("5");
    }
  };

  const handlePropertyTypeChange = (event) => {
    const selectedType = event.target.value;
    if (event.target.checked) {
      setPropertyType([...propertyType, selectedType]);
    } else {
      setPropertyType(propertyType.filter((type) => type !== selectedType));
    }
  };

  const parentLocations = {
    103: ["4", "5", "6", "25", "57", "64", "67", "75", "84", "157", "158"],
    104: [
      "2",
      "8",
      "24",
      "30",
      "31",
      "41",
      "77",
      "82",
      "98",
      "134",
      "147",
      "166",
      "171",
      "178",
      "181",
      "184",
    ],
    105: ["27", "124", "163", "187"],
    106: [
      "1",
      "11",
      "14",
      "45",
      "58",
      "65",
      "74",
      "96",
      "126",
      "145",
      "150",
      "155",
      "164",
      "176",
    ],
    113: [],
    114: ["122", "73", "81"],
    115: ["51", "89", "101", "107", "161", "189"],
    116: ["18", "149"],
    117: ["169"],
  };

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    const locationName = event.target.getAttribute("data-location-name");
    const isChecked = event.target.checked;

    if (parentLocations[selectedLocation]) {
      // Handle parent location
      if (isChecked) {
        // Check all children
        const newSelectedLocations = [
          ...selectedLocations,
          {
            id: selectedLocation,
            name: findLocationName(selectedLocation),
          },
          ...parentLocations[selectedLocation].map((childId) => ({
            id: childId,
            name: findLocationName(childId),
          })),
        ];
        setSelectedLocations([...new Set(newSelectedLocations)]);
      } else {
        // Uncheck all children
        const newSelectedLocations = selectedLocations.filter(
          (location) =>
            location.id !== selectedLocation &&
            !parentLocations[selectedLocation].includes(location.id)
        );
        setSelectedLocations(newSelectedLocations);
      }
    } else {
      // Handle child location
      if (isChecked) {
        setSelectedLocations([
          ...selectedLocations,
          { id: selectedLocation, name: locationName },
        ]);
      } else {
        setSelectedLocations(
          selectedLocations.filter(
            (location) => location.id !== selectedLocation
          )
        );
      }
    }
  };

  // Helper function to find location name by id
  const findLocationName = (id) => {
    const location = locations.find((loc) => loc.id === id);
    return location ? location.name : "";
  };

  const handleChange = (event) => {
    setSelectOption(event.target.value);
  };

  const pagehandleChange = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const buttonRef = useRef(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleScroll = () => {
    if (open && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isVisible) {
        handleClose(); // Close the menu if the button goes out of view
      }
    }
  };

  useEffect(() => {
    if (open) {
      // Add scroll event listener when the menu is open
      window.addEventListener("scroll", handleScroll);
    } else {
      // Remove scroll event listener when the menu is closed
      window.removeEventListener("scroll", handleScroll);
    }

    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [open]);
  const handleDesecendingPrice = () => {
    let sortedDataEng = [...engData].sort(
      (a, b) =>
        parseFloat(a.elements.kaufpreis) - parseFloat(b.elements.kaufpreis)
    );
    let sortedDataEsp = [...espData].sort(
      (a, b) =>
        parseFloat(a.elements.kaufpreis) - parseFloat(b.elements.kaufpreis)
    );
    let sortedDataGer = [...gerData].sort(
      (a, b) =>
        parseFloat(a.elements.kaufpreis) - parseFloat(b.elements.kaufpreis)
    );
    setEngData(sortedDataEng);
    setGerData(sortedDataGer);
    setEspData(sortedDataEsp);
    selectLanguage === "de"
      ? setData(sortedDataGer)
      : selectLanguage === "es"
      ? setData(sortedDataEsp)
      : setData(sortedDataEng);
    // setData(sortedData);
    // fetchAllPropertyListEstateData('DESC', '');
    setPriceSort("DESC");
    setTimeSort("");
    handleClose();
  };
  const handleAscendingPrice = () => {
    // // console.log("second", data);
    // // console.log("first");
    let sortedDataEng = [...engData].sort(
      (a, b) =>
        parseFloat(b.elements.kaufpreis) - parseFloat(a.elements.kaufpreis)
    );
    let sortedDataEsp = [...espData].sort(
      (a, b) =>
        parseFloat(b.elements.kaufpreis) - parseFloat(a.elements.kaufpreis)
    );
    let sortedDataGer = [...gerData].sort(
      (a, b) =>
        parseFloat(b.elements.kaufpreis) - parseFloat(a.elements.kaufpreis)
    );
    setEngData(sortedDataEng);
    setGerData(sortedDataGer);
    setEspData(sortedDataEsp);
    selectLanguage === "de"
      ? setData(sortedDataGer)
      : selectLanguage === "es"
      ? setData(sortedDataEsp)
      : setData(sortedDataEng);
    // let sortedData = [...data].sort(
    //   (a, b) =>
    //     parseFloat(b.elements.kaufpreis) - parseFloat(a.elements.kaufpreis)
    // );
    // setData(sortedData);
    // fetchAllPropertyListEstateData('ASC', '');
    setPriceSort("ASC");
    setTimeSort("");
    handleClose();
  };
  const handleOlderProperty = () => {
    let sortedDataEng = [...engData].sort(
      (a, b) =>
        new Date(b.elements.erstellt_am) - new Date(a.elements.erstellt_am)
    );
    let sortedDataEsp = [...espData].sort(
      (a, b) =>
        new Date(b.elements.erstellt_am) - new Date(a.elements.erstellt_am)
    );
    let sortedDataGer = [...gerData].sort(
      (a, b) =>
        new Date(b.elements.erstellt_am) - new Date(a.elements.erstellt_am)
    );
    setEngData(sortedDataEng);
    setGerData(sortedDataGer);
    setEspData(sortedDataEsp);
    selectLanguage === "de"
      ? setData(sortedDataGer)
      : selectLanguage === "es"
      ? setData(sortedDataEsp)
      : setData(sortedDataEng);
    // let sortedData = [...data].sort(
    //   (a, b) =>
    //     new Date(a.elements.erstellt_am) - new Date(b.elements.erstellt_am)
    // );
    // setData(sortedData);
    // setTimeSort('DESC');
    // fetchAllPropertyListEstateData("", "DESC");
    setPriceSort("");
    handleClose();
  };
  const handleNewerProperty = () => {
    let sortedDataEng = [...engData].sort(
      (a, b) =>
        new Date(a.elements.erstellt_am) - new Date(b.elements.erstellt_am)
    );
    let sortedDataEsp = [...espData].sort(
      (a, b) =>
        new Date(a.elements.erstellt_am) - new Date(b.elements.erstellt_am)
    );
    let sortedDataGer = [...gerData].sort(
      (a, b) =>
        new Date(a.elements.erstellt_am) - new Date(b.elements.erstellt_am)
    );
    setEngData(sortedDataEng);
    setGerData(sortedDataGer);
    setEspData(sortedDataEsp);
    selectLanguage === "de"
      ? setData(sortedDataGer)
      : selectLanguage === "es"
      ? setData(sortedDataEsp)
      : setData(sortedDataEng);
    // let sortedData = [...data].sort(
    //   (a, b) =>
    //     new Date(b.elements.erstellt_am) - new Date(a.elements.erstellt_am)
    // );
    // setData(sortedData);
    // setTimeSort('ASC');
    // fetchAllPropertyListEstateData("", "ASC");
    setPriceSort("");
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterData = async (
    updatedMaxPrice = maxPrice,
    updatedMinPrice = minPrice,
    updatedSelectedLocations = selectedLocations,
    updatedPropertyType = propertyType,
    updatedNoOfBed = noOfBed,
    updatedRefNo = refNo,
    updatedNoOfBath = noOfBath,
    updatedPropArea = propArea,
    updatedAddedLocation = addedLocation,
    data,
    visible = false
  ) => {
    // console.log(
    //   updatedMaxPrice,
    //   updatedMinPrice,
    //   updatedSelectedLocations,
    //   updatedPropertyType,
    //   updatedNoOfBed,
    //   updatedRefNo,
    //   updatedNoOfBath,
    //   updatedPropArea
    // );

    const min = parseFloat(updatedMinPrice) || 0;
    const max = parseFloat(updatedMaxPrice) || Infinity;
    const beds = parseFloat(updatedNoOfBed) || 0;
    const baths = parseInt(updatedNoOfBath) || 0; // New
    const area = parseInt(updatedPropArea) || 0;

    // console.log("+++FILTER ++++", min, max, beds, baths, area);
    const estatesDataEng = engEstates.length == 0 ? data : engEstates;
    // console.log(engEstates.length, estatesDataEng);
    const filteredEng = estatesDataEng?.filter((item) => {
      const price = parseFloat(item.elements.kaufpreis);
      const bedrooms = parseInt(item.elements.anzahl_schlafzimmer);
      const objektart = item.elements.objektart.toLowerCase();
      const ort = item?.elements?.ort?.toLowerCase();
      const refNumber = item.elements.objektnr_extern.toLowerCase();
      const bathrooms = parseInt(item.elements.anzahl_badezimmer); // New
      const wohnflaeche = parseInt(item.elements.wohnflaeche); // New

      const newLocation = inputValue.trim();
      if (newLocation) {
        handleAddLocation();
      }
      const typeMatches =
        updatedPropertyType.length == 0 ||
        updatedPropertyType.some((type) => {
          if (type === "sonstige") {
            return ![
              "haus",
              "villa",
              "wohnung",
              "grundstueck",
              "gewerbe",
              "gastgewerbe",
              "einzelhandel",
              "neubau",
              "ind_Schl_2969",
              "ind_schl_2969",
            ].includes(objektart);
          } else if (type === "gewerbe") {
            return objektart === "gewerbe" || objektart === "gastgewerbe";
          } else if (type === "neubau") {
            return (
              objektart === "ind_Schl_2969" || objektart === "ind_schl_2969"
            );
          } else {
            return objektart === type;
          }
        });

      const locationMatches =
        updatedSelectedLocations.length === 0 ||
        updatedSelectedLocations.some((location) =>
          ort.toLowerCase().includes(location?.name.toLowerCase())
        );

      const locationAddedMatches =
        updatedAddedLocation.length === 0 ||
        updatedAddedLocation.some((location) =>
          ort.toLowerCase().includes(location?.name.toLowerCase())
        );
      const refNoMatches =
        updatedRefNo == "" ||
        (typeof updatedRefNo == "string" &&
          refNumber
            .replace(/\s+/g, "")
            .toLowerCase()
            .includes(updatedRefNo.replace(/\s+/g, "").toLowerCase()));

      return (
        price >= min &&
        price <= max &&
        (beds == 0 || bedrooms == beds) &&
        (baths == 0 || bathrooms == baths) &&
        (area == 0 || wohnflaeche == area) && // New
        typeMatches &&
        locationMatches &&
        refNoMatches &&
        locationAddedMatches
      );
    });
    const estatesDataEsp = espEstates.length == 0 ? data : espEstates;
    // console.log(estatesDataEsp, "estatesDataEsp --------------");
    const filteredEsp = estatesDataEsp?.filter((item) => {
      const price = parseFloat(item.elements.kaufpreis);
      const bedrooms = parseInt(item.elements.anzahl_schlafzimmer);
      const objektart = item.elements.objektart.toLowerCase();
      const ort = item?.elements?.ort?.toLowerCase();
      const refNumber = item.elements.objektnr_extern.toLowerCase();
      const bathrooms = parseInt(item.elements.anzahl_badezimmer); // New
      const wohnflaeche = parseInt(item.elements.wohnflaeche); // New

      const typeMatches =
        updatedPropertyType.length == 0 ||
        updatedPropertyType.some((type) => {
          if (type === "sonstige") {
            return ![
              "haus",
              "villa",
              "wohnung",
              "grundstueck",
              "gewerbe",
              "gastgewerbe",
              "einzelhandel",
              "neubau",
              "ind_Schl_2969",
              "ind_schl_2969",
            ].includes(objektart);
          } else if (type === "gewerbe") {
            return objektart === "gewerbe" || objektart === "gastgewerbe";
          } else if (type === "neubau") {
            return (
              objektart === "ind_Schl_2969" || objektart === "ind_schl_2969"
            );
          } else {
            return objektart === type;
          }
        });

      const locationMatches =
        updatedSelectedLocations.length === 0 ||
        updatedSelectedLocations.some((location) =>
          ort.toLowerCase().includes(location?.name.toLowerCase())
        );
      const locationAddedMatches =
        updatedAddedLocation.length === 0 ||
        updatedAddedLocation.some((location) =>
          ort.toLowerCase().includes(location?.name.toLowerCase())
        );
      const refNoMatches =
        updatedRefNo === "" ||
        (typeof updatedRefNo === "string" &&
          refNumber
            .replace(/\s+/g, "")
            .toLowerCase()
            .includes(updatedRefNo.replace(/\s+/g, "").toLowerCase()));

      return (
        price >= min &&
        price <= max &&
        (beds === 0 || bedrooms == beds) &&
        (baths === 0 || bathrooms == baths) &&
        (area === 0 || wohnflaeche == area) && // New
        typeMatches &&
        locationMatches &&
        refNoMatches &&
        locationAddedMatches
      );
    });
    const estatesDataGer = gerEstates.length == 0 ? data : gerEstates;
    // console.log("estatesDataGer ----------", estatesDataGer);
    const filteredGer = estatesDataGer?.filter((item) => {
      const price = parseFloat(item.elements.kaufpreis);
      const bedrooms = parseInt(item.elements.anzahl_schlafzimmer);
      const objektart = item.elements.objektart.toLowerCase();
      const ort = item?.elements?.ort?.toLowerCase();
      const refNumber = item.elements.objektnr_extern.toLowerCase();
      const bathrooms = parseInt(item.elements.anzahl_badezimmer); // New
      const wohnflaeche = parseInt(item.elements.wohnflaeche); // New

      const typeMatches =
        updatedPropertyType.length == 0 ||
        updatedPropertyType.some((type) => {
          if (type === "sonstige") {
            return ![
              "haus",
              "villa",
              "wohnung",
              "grundstueck",
              "gewerbe",
              "gastgewerbe",
              "einzelhandel",
              "neubau",
              "ind_Schl_2969",
              "ind_schl_2969",
            ].includes(objektart);
          } else if (type === "gewerbe") {
            return objektart === "gewerbe" || objektart === "gastgewerbe";
          } else if (type === "neubau") {
            return (
              objektart === "ind_Schl_2969" || objektart === "ind_schl_2969"
            );
          } else {
            return objektart === type;
          }
        });

      const locationMatches =
        updatedSelectedLocations.length === 0 ||
        updatedSelectedLocations.some((location) =>
          ort.toLowerCase().includes(location?.name.toLowerCase())
        );
      const locationAddedMatches =
        updatedAddedLocation.length === 0 ||
        updatedAddedLocation.some((location) =>
          ort.toLowerCase().includes(location?.name.toLowerCase())
        );
      const refNoMatches =
        updatedRefNo === "" ||
        (typeof updatedRefNo === "string" &&
          refNumber
            .replace(/\s+/g, "")
            .toLowerCase()
            .includes(updatedRefNo.replace(/\s+/g, "").toLowerCase()));

      return (
        price >= min &&
        price <= max &&
        (beds === 0 || bedrooms == beds) &&
        (baths === 0 || bathrooms == baths) &&
        (area === 0 || wohnflaeche == area) && // New
        typeMatches &&
        locationMatches &&
        refNoMatches &&
        locationAddedMatches
      );
    });
    // console.log("FilteredEng 2 ----------", filteredEng);
    // console.log("Visible", visible);
    // console.log("updatedNoOfBed", noOfBed);
    // console.log(
    //   "updatedPropertyType.length",
    //   propertyType.length,
    //   propertyType,
    //   updatedPropertyType,
    //   updatedPropertyType.length
    // );
    // console.log(
    //   "updatedSelectedLocations.length",
    //   selectedLocations.length,
    //   selectedLocations
    // );
    // console.log("updatedMinPrice", minPrice);
    // console.log("updatedMaxPrice", maxPrice);
    // console.log("updatedNoOfBath", noOfBath);
    // console.log("updatedPropArea", propArea);
    // console.log("updatedRefNo", updatedRefNo);
    setVisibleComponent(null);

    // updatedMaxPrice = maxPrice,
    // updatedMinPrice = minPrice,
    // updatedSelectedLocations = selectedLocations,
    // updatedPropertyType = propertyType,
    // updatedNoOfBed = noOfBed,
    // updatedRefNo = refNo,
    // updatedNoOfBath = noOfBath,
    // updatedPropArea

    updatedNoOfBed === "" &&
    updatedPropertyType.length == 0 &&
    updatedSelectedLocations.length == 0 &&
    updatedMinPrice === "" &&
    updatedMaxPrice === "" &&
    updatedNoOfBath === "" &&
    propArea === "" &&
    updatedRefNo === "" &&
    updatedAddedLocation.length == 0
      ? setShowFilters(false)
      : visible == true
      ? setShowFilters(true)
      : setShowFilters(true);

    // updatedNoOfBed === "" &&
    // updatedPropertyType.length == 0 &&
    // updatedSelectedLocations.length == 0 &&
    // updatedMinPrice === "" &&
    // updatedMaxPrice === "" &&
    // updatedNoOfBath === "" &&
    // propArea === "" &&
    // updatedRefNo === "" &&
    // updatedAddedLocation.length == 0
    //   && setPage(1)

    setEngData(filteredEng);
    setGerData(filteredGer);
    setEspData(filteredEsp);

    i18n.language.startsWith("de")
      ? setData(filteredGer)
      : i18n.language.startsWith("es")
      ? setData(filteredEsp)
      : setData(filteredEng);

    setFilters({
      refNo: updatedRefNo,
      minPrice: updatedMinPrice,
      maxPrice: updatedMaxPrice,
      selectedLocations: updatedSelectedLocations,
      propertyType: updatedPropertyType,
      noOfBed: updatedNoOfBed,
      noOfBath: updatedNoOfBath,
      propArea: updatedPropArea,
    });
    // console.log("LAST");
    // updatedMaxPrice,
    //   updatedMinPrice,
    //   updatedSelectedLocations,
    //   updatedPropertyType,
    //   updatedNoOfBed,
    //   updatedRefNo,
    //   updatedNoOfBath,
    //   updatedPropArea
  };

  const handleRemoveFilter = (filterType, value) => {
    // console.log(filterType, value);
    let updatedRefNo = filters.refNo == undefined ? "" : filters.refNo;
    let updatedMaxPrice = filters.maxPrice;
    let updatedMinPrice = filters.minPrice;
    let updatedSelectedLocations = filters.selectedLocations;
    let updatedPropertyType = filters.propertyType;
    let updatedNoOfBed = filters.noOfBed;
    let updatedNoOfBath = filters.noOfBath;
    let updatedPropArea = filters.propArea;
    let updatedAddedLocation = filters.addedLocation;

    // console.log("updatedNoOfBed", updatedNoOfBed);
    // console.log(
    //   "updatedPropertyType.length",
    //   updatedPropertyType.length,
    //   updatedPropertyType
    // );
    // console.log(
    //   "updatedSelectedLocations.length",
    //   updatedSelectedLocations.length,
    //   updatedSelectedLocations,
    //   updatedAddedLocation
    // );
    // console.log("updatedMinPrice", updatedMinPrice);
    // console.log("updatedMaxPrice", updatedMaxPrice);
    // console.log("updatedNoOfBath", updatedNoOfBath);
    // console.log("updatedPropArea", updatedPropArea);
    // console.log("updatedRefNo", updatedRefNo);

    switch (filterType) {
      case "refNo":
        updatedRefNo = "";
        setRefNo("");

        break;
      case "maxPrice":
        updatedMaxPrice = "";
        setMaxPrice("");

        break;
      case "minPrice":
        updatedMinPrice = "";
        setMinPrice("");

        break;
      case "selectedLocations":
        updatedSelectedLocations = updatedSelectedLocations?.filter(
          (loc) => loc.id !== value.id
        );
        setSelectedLocations(updatedSelectedLocations);

        break;
      case "addedLocations":
        updatedAddedLocation = addedLocation?.filter(
          (loc) => loc.id !== value.id
        );
        setAddedLocation(updatedAddedLocation);

        break;
      case "propertyType":
        updatedPropertyType = propertyType?.filter((type) => type !== value);
        setPropertyType(updatedPropertyType);

        break;
      case "noOfBed":
        updatedNoOfBed = "";
        setNoOfBed("");

        break;
      case "noOfBath":
        updatedNoOfBath = "";
        setNoOfBath("");

        break;
      case "propArea":
        updatedPropArea = "";
        setPropArea("");

        break;
      default:
        break;
    }

    filterData(
      updatedMaxPrice,
      updatedMinPrice,
      updatedSelectedLocations,
      updatedPropertyType,
      updatedNoOfBed,
      updatedRefNo,
      updatedNoOfBath,
      updatedPropArea,
      updatedAddedLocation
    );
    setFilters({
      refNo: updatedRefNo,
      minPrice: updatedMinPrice,
      maxPrice: updatedMaxPrice,
      selectedLocations: updatedSelectedLocations,
      propertyType: updatedPropertyType,
      noOfBed: updatedNoOfBed,
      noOfBath: updatedNoOfBath,
      propArea: updatedPropArea,
    });
    // // console.log(updatedNoOfBath, updatedPropArea);

    updatedNoOfBed === "" &&
    updatedPropertyType.length == 0 &&
    updatedSelectedLocations.length == 0 &&
    updatedMinPrice === "" &&
    updatedMaxPrice === "" &&
    updatedNoOfBath === "" &&
    updatedPropArea === "" &&
    updatedRefNo === "" &&
    updatedAddedLocation.length == 0
      ? handleClearAll()
      : setShowFilters(true);

    // console.log("updatedNoOfBed", updatedNoOfBed);
    // console.log("updatedPropertyType.length", updatedPropertyType.length);
    // console.log(
    //   "updatedSelectedLocations.length",
    //   updatedSelectedLocations.length
    // );
    // console.log("updatedMinPrice", updatedMinPrice);
    // console.log("updatedMaxPrice", updatedMaxPrice);
    // console.log("updatedNoOfBath", updatedNoOfBath);
    // console.log("updatedPropArea", updatedPropArea);
    // console.log("updatedRefNo", updatedRefNo);
    setPage(1);
  };

  const handleClearAll = () => {
    // console.log("[[[[[[[[[[[[[[[[]]]]]]]]]]]]]]]]]]]", allData);
    setMaxPrice("");
    setMinPrice("");
    setSelectedLocations([]);
    setPropertyType([]);
    setNoOfBed("");
    setData(allData);
    setNoOfBath(""); //
    setPropArea("");
    setRefNo("");
    setAddedLocation([]);
    setAllFilterData({
      refNo: "",
      minPrice: "",
      maxPrice: "",
      selectedLocations: [],
      propertyType: [],
      noOfBed: "",
      noOfBath: "",
      propArea: "",
    });
    clearFilters();
    setShowFilters(false);
    setEspData(dataESP);
    setGerData(dataGER);
    setEngData(dataENG);
    setPage(1);
    history.replace({
      ...location,
      state: {},
    });
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.trim() !== "" && selectedLocations.length > 0) {
      // Alert when there are selected checkboxes and search input is not empty
      alert(t("alertText"));

      // Clear selected checkboxes
      setSelectedLocations([]);
    }
  };

  const handleAddLocation = async () => {
    const newLocation = inputValue.trim();
    if (!newLocation) return;

    // Check if location is already selected
    if (!selectedLocations.some((location) => location.name === newLocation)) {
      setSelectedLocations([
        ...selectedLocations,
        { id: newLocation, name: newLocation },
      ]);
      // setAddedLocation([
      //   ...addedLocation,
      //   { id: newLocation, name: newLocation },
      // ])
      setLocationAdded(true); // Mark that a location has been added
    }

    // Clear the input field
    setInputValue("");
  };

  // Trigger filterData only when location is added via input
  useEffect(() => {
    if (locationAdded) {
      // const timeoutId = setTimeout(() => {
      filterData(
        maxPrice,
        minPrice,
        selectedLocations,
        propertyType,
        noOfBed,
        "",
        noOfBath,
        propArea,
        addedLocation
      );
      setLocationAdded(false); // Reset after filterData is called
      // }, 1000);

      // Cleanup the timeout if component unmounts or state changes
      // return () => clearTimeout(timeoutId);
    }
  }, [locationAdded]);

  const commonSearchBox = () => ({
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderRadius: "50px",
        boxShadow: "0px 2px 10px 3px rgba(0,0,0,0.24)",
      },
    },

    "& .MuiOutlinedInput-root.Mui-focused": {
      "& > fieldset": {
        borderColor: "orange",
      },
    },

    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "orange",
        border: "solid 0.5px orange",
      },
    },
    "&:hover": {
      borderRadius: "50px",
    },
  });

  /**
   * Handling Enyter Keydown
   * @param {React.KeyboardEvent} e
   * @returns {void}
   */

  const handleEnterPressSearch = (e) => {
    if (e.key === "Enter" || e.code === "Enter" || e.code === "NumpadEnter") {
      actionRef.current?.click();
    }
  };
  return (
    <>
      <Helmet>
        <meta itemprop="inLanguage" content="de" />
        <meta
          name="description"
          itemprop="description"
          content={"All Properties"}
        />

        <link
          rel="canonical"
          href={`https://www.sae-mallorca.com/propertysearch`}
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/propertysearch`}
          hreflang="de"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/propertysearch`}
          hreflang="en"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/propertysearch`}
          hreflang="es"
        />
        {/* <link rel="icon" href={`${mainImage}`} /> */}
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"All Properties"} />
        {/* <meta property="og:image" content={`${mainImage}`} /> */}
        <meta property="og:description" content={"All Properties"} />
        <meta
          property="og:url"
          content={`https://www.sae-mallorca.com/propertysearch`}
        />
        <meta
          property="og:site_name"
          content="Immobilien Mallorca kaufen |S.A.E Real Estate Mallorca"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={"All Properties"} />
        <meta name="twitter:title" content={"All Properties"} />
      </Helmet>
      <div className="inner_cont_wrap">
        <div className="auto c_3">
          <div className="c_form_loading">
            <div className="row group center_vh">
              {/* <div className="material-textfield">
          <input placeholder="Ref. Nr." type="text" />
          <label> Ref. Nr.</label>
          </div> */}
              <div className="c_virtual c_field" style={{ maxWidth: 150 }}>
                <a
                  href="#"
                  className="row keep center_vh"
                  onClick={() => {
                    setInputValue("");
                    handleShowComponent("Component1");
                  }}
                >
                  <span className="label">{t("price")}</span>
                  <i className="down c_arrows"></i>
                </a>
              </div>
              <div className="c_virtual c_field">
                <a
                  href="#"
                  className="row keep center_vh"
                  onClick={() => {
                    setInputValue("");
                    handleShowComponent("Component2");
                  }}
                >
                  <span className="label">{t("propertyType")}</span>
                  <i className="down c_arrows"></i>
                </a>
              </div>
              <div className="c_virtual c_field">
                <a
                  href="#"
                  className="row keep center_vh"
                  onClick={() => {
                    setInputValue("");
                    handleShowComponent("Component3");
                  }}
                >
                  <span className="label">{t("location")}</span>
                  <i className="down c_arrows"></i>
                </a>
              </div>
              {/* <div className="new-cat">
              <select>
                <option value="">
                  Badroom
                </option>
                <option value="">
                  1 Room
                </option>
                <option value="">
                  2 Rooms
                </option>
                <option value="">
                  3 Rooms
                </option>
                <option value="">
                  4 Rooms
                </option>
              </select>
            </div> */}
              <div
                className="material-textfield"
                style={{ maxWidth: 130, fontSize: 14 }}
              >
                <input
                  id="data_estates__bathroom"
                  placeholder={t("bathrooms")}
                  type="number"
                  value={noOfBath}
                  onKeyDown={handleEnterPressSearch}
                  onChange={handleNoOfBathChange}
                  onClick={() => {
                    setVisibleComponent(null);
                    setInputValue("");
                  }}
                />
                <label style={{ fontSize: 15 }}>{t("bathrooms")}</label>
              </div>
              <div
                className="material-textfield"
                style={{ maxWidth: 125, fontSize: 14 }}
              >
                <input
                  id="data_estates__bedroom"
                  placeholder={t("bedrooms")}
                  type="number"
                  value={noOfBed}
                  onKeyDown={handleEnterPressSearch}
                  onChange={handleNoOfBedChange}
                  onClick={() => {
                    setVisibleComponent(null);
                    setInputValue("");
                  }}
                />
                <label style={{ fontSize: 15 }}>{t("bedrooms")}</label>
              </div>
              {/* <div className="new-cat">
              <select>
                <option value="">
                  Size
                </option>
                <option value="">
                  12ft - 16ft
                </option>
                <option value="">
                  16ft - 20ft
                </option>
                <option value="">
                  20ft - 20ft
                </option>
              </select>
            </div> */}
              <div
                className="material-textfield"
                style={{ maxWidth: 125, fontSize: 14 }}
              >
                <input
                  id="data_estates__area"
                  placeholder={t("area")}
                  type="number"
                  value={propArea}
                  onKeyDown={handleEnterPressSearch}
                  onChange={handlePropAreaChange}
                  onClick={() => {
                    setVisibleComponent(null);
                    setInputValue("");
                  }}
                />
                <label style={{ fontSize: 15 }}>{t("area")}(㎡)</label>
              </div>
              {/* <div className="material-textfield">
              <input
                id="data_estates__bedroom"
                placeholder={t("bedrooms")}
                type="number"
                value={noOfBed}
                onChange={handleNoOfBedChange}
              />
              <label>{t("bedrooms")}</label>
            </div> */}
              {/* {inputValue.trim() === '' && */}
              <div
                className="action_button"
                ref={actionRef}
                onClick={async () => {
                  setPage(1);
                  setTimeout(() => {
                    filterData(
                      maxPrice,
                      minPrice,
                      selectedLocations,
                      propertyType,
                      noOfBed,
                      "",
                      noOfBath,
                      propArea,
                      addedLocation,
                      data
                    );
                  });
                }}
              >
                <a href="#" className="button index_0 ">
                  {t("searchButton")}
                </a>
              </div>
              {/* } */}
            </div>
            {visibleComponent === "Component1" && (
              <div className="virtual group  from_optview1">
                <div className="row center_v c_form_range_inputs">
                  <div className="inp_row_cont wrapper-row-content">
                    <div
                      className="list-name-content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                      }}
                    >
                      €
                      <div
                        className="material-textfield"
                        style={{ marginLeft: 10 }}
                      >
                        {/* <div style={{marginRight:15}}>€</div> */}

                        <input
                          placeholder={t("priceFrom")}
                          type="number"
                          onKeyDown={handleEnterPressSearch}
                          value={minPrice}
                          onChange={handleMinPriceChange}
                        />
                        <label>{t("priceFrom")}</label>
                      </div>
                    </div>
                    <div
                      className="list-name-content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10,
                        flexGrow: 1,
                      }}
                    >
                      €
                      <div
                        className="material-textfield"
                        style={{ marginLeft: 10 }}
                      >
                        <input
                          placeholder={t("priceTo")}
                          type="number"
                          value={maxPrice}
                          onKeyDown={handleEnterPressSearch}
                          onChange={handleMaxPriceChange}
                        />
                        <label>{t("priceTo")}</label>
                      </div>
                    </div>
                    {/* <div
                    className="def_btn_opt list-name-content"
                    onClick={priceButtonClick}
                  >
                    <button>{t("useSelection")}</button>
                  </div> */}
                  </div>
                </div>
              </div>
            )}
            {visibleComponent === "Component2" && (
              <div className="virtual group from_optview2">
                <fieldset className="c_form_checkbox_list c_field_group">
                  <legend>{t("propertyType")}</legend>

                  <div className="list">
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="1" data-id="id_0">
                        <input
                          type="checkbox"
                          id="checkbox_id_0"
                          data-value="1"
                          data-id="id_0"
                          value="haus"
                          checked={propertyType.includes("haus")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("house")}
                      </label>
                    </div>
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="2" data-id="id_1">
                        <input
                          type="checkbox"
                          id="checkbox_id_1"
                          data-value="2"
                          data-id="id_1"
                          value={"wohnung"}
                          checked={propertyType.includes("wohnung")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("apartment")}
                      </label>
                    </div>
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="4" data-id="id_2">
                        <input
                          type="checkbox"
                          id="checkbox_id_2"
                          data-value="4"
                          data-id="id_2"
                          value={"grundstueck"}
                          checked={propertyType.includes("grundstueck")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("land")}
                      </label>
                    </div>
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="5" data-id="id_3">
                        <input
                          type="checkbox"
                          id="checkbox_id_3"
                          data-value="5"
                          data-id="id_3"
                          value={"gastgewerbe"}
                          checked={propertyType.includes("gastgewerbe")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("commercial")}
                      </label>
                    </div>
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="6" data-id="id_4">
                        <input
                          type="checkbox"
                          id="checkbox_id_4"
                          data-value="6"
                          data-id="id_4"
                          value={"einzelhandel"}
                          checked={propertyType.includes("einzelhandel")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("retail")}
                      </label>
                    </div>
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="8" data-id="id_5">
                        <input
                          type="checkbox"
                          id="checkbox_id_5"
                          data-value="8"
                          data-id="id_5"
                          value={"neubau"}
                          checked={propertyType.includes("neubau")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("newBuild")}
                      </label>
                    </div>
                    <div className="c_form_checkbox c_field">
                      <label className="" data-value="7" data-id="id_6">
                        <input
                          type="checkbox"
                          id="checkbox_id_6"
                          data-value="7"
                          data-id="id_6"
                          value={"sonstige"}
                          checked={propertyType.includes("sonstige")}
                          onChange={handlePropertyTypeChange}
                        />
                        {t("other")}
                      </label>
                    </div>
                  </div>
                  <span></span>
                </fieldset>
              </div>
            )}
            {visibleComponent === "Component3" && (
              <div className="virtual group open from_optview3">
                <fieldset className="c_form_checkbox_list_tree c_field_group">
                  <legend>{t("location")}</legend>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      marginBottom: 2,
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <div className="search-container">
                      <SearchIcon
                        style={{ marginRight: "5px", color: "#aaa" }}
                      />
                      <input
                        type="text"
                        placeholder={t("searchLocation")}
                        onKeyDown={handleEnterPressSearch}
                        className="search-input"
                        onChange={handleInputChange}
                        style={{ border: "none", fontSize: "16px" }}
                      />
                    </div>
                    {/* {inputValue.trim() !== '' &&
                  <div
              // className="action_button"
              style={{border: "1px solid #ffF",
                // whiteSpace: "nowrap",
                boxShadow: "rgba(0, 0, 1, 0.2) 0px 7px 29px 0px",
                padding: "9.9px 36px",
                padding: "0.55rem 2rem",
                backgroundColor: "#6c757d",
                display: "block",
                textAlign: "center",
                // color:"#fff",
                fontWeight: 600,}}
              onClick={handleAddLocation}
            >
              <a href="#" style={{color:'#fff', fontWeight: 600,}}>
                {t("searchButton")}
              </a>
            </div>
} */}
                    {/* <Button
                    variant="contained"
                    // color="#000"
                    // onClick={handleAddLocation}
                    sx={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      backgroundColor: "#000",
                      color: "#fff",
                      textTransform: "capitalize",
                      width: { xs: '100%', sm: '20%' }, 
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    {t('searchButton')}
                  </Button> */}
                  </Box>
                  <div>
                    <legend style={{ top: -5, fontSize: "20px" }}>
                      {t("locHeader")}{" "}
                    </legend>
                  </div>
                  <div className="list">
                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="103" data-id="id_103">
                          <input
                            type="checkbox"
                            id="checkbox_id_103"
                            data-value="103"
                            data-location-name="Mallorca Süd"
                            value="103"
                            checked={selectedLocations.some(
                              (location) => location.id === "103"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Süd")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["103"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="104" data-id="id_104">
                          <input
                            type="checkbox"
                            id="checkbox_id_104"
                            data-value="104"
                            data-location-name="Mallorca Süd"
                            value="104"
                            checked={selectedLocations.some(
                              (location) => location.id === "104"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Süd West")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["104"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="105" data-id="id_105">
                          <input
                            type="checkbox"
                            id="checkbox_id_105"
                            data-value="105"
                            data-location-name="Mallorca Süd"
                            value="105"
                            checked={selectedLocations.some(
                              (location) => location.id === "105"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Zentrum")}
                        </label>
                      </div>

                      <div className="list row start wrap">
                        {parentLocations["105"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="106" data-id="id_106">
                          <input
                            type="checkbox"
                            id="checkbox_id_106"
                            data-value="106"
                            data-location-name="Mallorca Süd"
                            value="106"
                            checked={selectedLocations.some(
                              (location) => location.id === "106"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Palma")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["106"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="l2">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="113" data-id="id_113">
                          <input
                            type="checkbox"
                            id="checkbox_id_113"
                            data-value="113"
                            data-location-name="Mallorca Süd"
                            value="113"
                            checked={selectedLocations.some(
                              (location) => location.id === "113"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Nord West")}
                        </label>
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="114" data-id="id_114">
                          <input
                            type="checkbox"
                            id="checkbox_id_114"
                            data-value="114"
                            data-location-name="Mallorca Süd"
                            value="114"
                            checked={selectedLocations.some(
                              (location) => location.id === "114"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Ost")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["114"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="115" data-id="id_115">
                          <input
                            type="checkbox"
                            id="checkbox_id_115"
                            data-value="115"
                            data-location-name="Mallorca Süd"
                            value="115"
                            checked={selectedLocations.some(
                              (location) => location.id === "115"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Nord")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["115"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="116" data-id="id_116">
                          <input
                            type="checkbox"
                            id="checkbox_id_116"
                            data-value="116"
                            data-location-name="Mallorca Süd"
                            value="116"
                            checked={selectedLocations.some(
                              (location) => location.id === "116"
                            )}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca West")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["116"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  data-location-name={locationMap[childId]}
                                  checked={selectedLocations.some(
                                    (location) => location.id === childId
                                  )}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {locationMap[childId]}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="has_children">
                      <div className="c_form_checkbox c_field">
                        <label className="" data-value="117" data-id="id_117">
                          <input
                            type="checkbox"
                            id={"checkbox_id_117"}
                            value="117"
                            checked={selectedLocations.includes("117")}
                            onChange={(event) => {
                              if (inputValue.trim() !== "") {
                                alert(t("alertText"));
                              } else {
                                handleLocationChange(event);
                              }
                            }}
                            // disabled={inputValue.trim() !== ''}
                          />
                          {t("Mallorca Süd Ost")}
                        </label>
                      </div>
                      <div className="list row start wrap">
                        {parentLocations["117"].map((childId) => (
                          <div key={childId} className="l2">
                            <div className="c_form_checkbox c_field">
                              <label
                                className=""
                                data-value={childId}
                                data-id={`id_${childId}`}
                              >
                                <input
                                  type="checkbox"
                                  id={`checkbox_id_${childId}`}
                                  value={childId}
                                  checked={selectedLocations.includes(childId)}
                                  onChange={(event) => {
                                    if (inputValue.trim() !== "") {
                                      alert(t("alertText"));
                                    } else {
                                      handleLocationChange(event);
                                    }
                                  }}
                                  // disabled={inputValue.trim() !== ''}
                                />
                                {childId === "169" && "Sa Rapita"}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <span></span>
                  <div></div>
                </fieldset>
              </div>
            )}

            {/* <div className="row spaced c_form_search_filters_selected">
            <div className="row keep wrap filters">
              <div className="tag">
                <a
                  href="#"
                  data-field="data_estates__bedroom"
                  className="row keep center_v"
                >
                  <img
                    className="c_icon"
                    src="https://www.sae-mallorca.com/assets/img/sprites/close-circle-outline.svg"
                    alt="Icon close-circle-outline"
                  />
                  <div>Ab 122 Schlafzimmer</div>
                </a>
              </div>
            </div>
            <div className="remove">
              <div className="tag">
                <a href="#" className="row keep center_v">
                  <img
                    className="c_icon"
                    src="https://www.sae-mallorca.com/assets/img/sprites/close-circle-outline.svg"
                    alt="Icon close-circle-outline"
                  />
                  Filter löschen
                </a>
              </div>
            </div>
          </div> */}
            {showFilters ? (
              <PropertyFilter
                // maxPrice={maxPrice}
                // minPrice={minPrice}
                // selectedLocations={selectedLocations}
                // addedLocation={addedLocation}
                // propertyType={propertyType}
                // noOfBed={noOfBed}
                // noOfBath={noOfBath}
                // propArea={propArea}

                maxPrice={filters.maxPrice}
                minPrice={filters.minPrice}
                selectedLocations={filters.selectedLocations}
                addedLocation={filters.addedLocation}
                propertyType={filters.propertyType}
                noOfBed={filters.noOfBed}
                noOfBath={filters.noOfBath}
                propArea={filters.propArea}
                setMaxPrice={setMaxPrice}
                setMinPrice={setMinPrice}
                setSelectedLocations={setSelectedLocations}
                setPropertyType={setPropertyType}
                setNoOfBed={setNoOfBed}
                data={data}
                refNo={filters.refNo}
                setData={setData}
                allData={allData}
                setAllData={setAllData}
                handleRemoveFilter={handleRemoveFilter}
                handleClearAll={handleClearAll}
              />
            ) : null}
          </div>
        </div>
        {loading.en || loading.es || loading.de ? (
          <div
            style={{
              minHeight: 400,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie options={defaultOptions} height={200} width={600} />
          </div>
        ) : (
          <>
            <div className="row keep spaced infos auto">
              <div>
                {data?.length} {t("foundProperties")}
              </div>
              <div className="c_list_sort defbasic_button">
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  ref={buttonRef}
                >
                  {t("sortBy")}
                </Button>

                <Popper
                  open={open}
                  anchorEl={anchorEl}
                  sx={{ zIndex: 500 }}
                  placement="bottom-start" // Ensures the menu opens below the button
                  modifiers={[
                    {
                      name: "flip",
                      enabled: false, // Disable the default flipping behavior to avoid floating
                    },
                    {
                      name: "preventOverflow",
                      options: {
                        altAxis: true, // Prevents overflow on the X-axis
                        tether: false, // Ensures the Popper stays within bounds
                      },
                    },
                  ]}
                  ref={buttonRef}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper>
                      <MenuItem onClick={handleAscendingPrice}>
                        {t("descendingPrice")}
                      </MenuItem>
                      <MenuItem onClick={handleDesecendingPrice}>
                        {t("ascendingPrice")}
                      </MenuItem>
                      <MenuItem onClick={handleOlderProperty}>
                        {t("newestFirst")}
                      </MenuItem>
                      <MenuItem onClick={handleNewerProperty}>
                        {t("oldestFirst")}
                      </MenuItem>
                    </Paper>
                  </ClickAwayListener>
                </Popper>
              </div>
            </div>

            <PropertySearchItems
              data={data}
              page={page}
              espData={dataESP}
              engData={dataENG}
              gerData={dataGER}
            />

            {/* <div className="pagination_opt"> */}
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 2, pb: 10 }}
            >
              <Pagination
                count={Math.ceil(data?.length / 12)}
                page={page}
                onChange={pagehandleChange}
                variant="outlined"
                shape="circular"
                showFirstButton
                showLastButton
                size="large"
                color="primary"
              />
            </Box>
            {/* </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default PropertySearch;

//  const webpUrl = `https://res.cloudinary.com/dfvtluymq/image/fetch/f_webp,q_auto,w_500,h_300,c_limit/${encodeURIComponent(image.url)}`;
