import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import Icon from '@mui/material/Icon';
import property_pic1 from "../../assets/img/property_pic1.png";
import property_pic2 from "../../assets/img/property_pic2.png";
import property_pic3 from "../../assets/img/property_pic3.png";
import property_pic4 from "../../assets/img/property_pic4.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Slider from "react-slick";

import { Swiper, SwiperSlide } from "swiper/react";
// import 'swiper/swiper-bundle.min.css';

import { useHistory } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useFavorites } from "../Context/FavoritesContext";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
// import 'swiper/css/pagination';
import { EffectFade, Navigation } from "swiper/modules";
import "./MySlider.css";
import { useTranslation } from "react-i18next";
// SwiperCore.use([Navigation]);

var slideopt1 = {
  spaceBetween: 50,
  slidesPerView: 1,
  navigation: true,
};
const PropertySearchItems = ({ data, page, espData, engData, gerData }) => {
  // console.log("data, page, espData, engData, gerData", data, page, espData, engData, gerData);
  // // console.log(gerData);
  const { t, i18n } = useTranslation();
  const swiperRef = useRef(null);

  const history = useHistory();
  const { favorites, toggleFavorite, espFavorites, gerFavorites } =
    useFavorites();
  const [paginatedEstates, setPaginatedEstates] = useState([]);
  const ITEMS_PER_PAGE = 12;

  useEffect(() => {
    let pages = page
    const newPaginatedEstates = data?.slice(
      (pages - 1) * ITEMS_PER_PAGE,
      pages * ITEMS_PER_PAGE
    );
    setPaginatedEstates(newPaginatedEstates);
  }, [data, page]);
  const formatPrice = (price) => {
    const locale = i18n.language.startsWith('en') ?  'en-US' : 'de-DE' ;
    return new Intl.NumberFormat(locale, {
      style: "decimal",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };
  const handleCardClick = (item, index) => {
    // console.log("selected", item);
    const engProperty = engData.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const gerProperty = gerData.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    const espProperty = espData.find(
      (data) => data.elements.objektnr_extern === item.elements.objektnr_extern
    );
    // console.log("ENG", engProperty);
    // console.log("espData", espProperty);
    // console.log("gerData", gerProperty);

    const sanitizeString = (str) => {
      return str
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .replace(/[^\w-]+/g, ""); // Remove all non-word characters except hyphens
    };

    const sanitizedOrt = sanitizeString(item.elements.ort);
    const sanitizedObjektart = sanitizeString(item.elements.objektart);
    const refId = sanitizeString(item.elements.objektnr_extern)

    const dynamicUrl = `/propertydetails/${sanitizedOrt}/${item.id}-${sanitizedObjektart}/${refId}`;

    setTimeout(() => {
      history.push({
        pathname: dynamicUrl,
        state: {
          property: engProperty,
          gerProperty: gerProperty,
          espProperty: espProperty,
        },
      });
    }, 1000);
  };
  // const getOptimizedImageUrl = (url) => {
  //   return url.replace(/(\.[a-zA-Z]{3,4})(\?.*)?$/, '.webp$2');
  // };
  return (
    <section className="aboutinfo">
      <div className="cm_15">
        <div className="auto">
          <div className="modf_def_cont_block"
            // className="row spaced"
             >
            {paginatedEstates.map((item, index) => {
              // const isFavorite = favorites.some((fav) => fav.id === item.id);
              const isFavorite =
                favorites.some(
                  (fav) =>
                    fav.elements.objektnr_extern ===
                    item.elements.objektnr_extern
                ) ||
                espFavorites.some(
                  (fav) =>
                    fav.elements.objektnr_extern ===
                    item.elements.objektnr_extern
                ) ||
                gerFavorites.some(
                  (fav) =>
                    fav.elements.objektnr_extern ===
                    item.elements.objektnr_extern
                );
              return (
                <div
                  className="c_5"
                  style={{ marginLeft: "0.5vw" }}
                  key={item.elements.objektnr_extern}
                >
                  <a
                    href="#"
                    className={`favicon row keep center_v c_selected_marker ${
                      isFavorite ? "favorite" : ""
                    }`}
                    onClick={(e) => {
                      e.preventDefault();
                      const engProperty = engData.find(
                        (data) =>
                          data.elements.objektnr_extern ===
                          item.elements.objektnr_extern
                      );
                      const gerProperty = gerData.find(
                        (data) =>
                          data.elements.objektnr_extern ===
                          item.elements.objektnr_extern
                      );
                      const espProperty = espData.find(
                        (data) =>
                          data.elements.objektnr_extern ===
                          item.elements.objektnr_extern
                      );
                      toggleFavorite(engProperty, espProperty, gerProperty);
                    }}
                  >
                    <FavoriteIcon
                      style={{ color: isFavorite ? "#e8c25f" : "#fff" }}
                    />
                  </a>

                  <div className="c_slider">
                    <div className="slides propertySerarchSlide">
                      <div className="slides_wrap_info">
                        <Swiper
                          className="mySwiper"
                          // effect={"fade"}
                          navigation={true}
                          modules={[Navigation]}
                          spaceBetween={50}
                          slidesPerView={1}
                        >
                          {item.elements.urls.map((image, i) => (
                            <SwiperSlide key={i}>
                              <div className="link" style={{cursor: "pointer"}} >
                                {/* <LazyLoadImage */}
                                <img
                                  src={image.url}
                                  alt="Property Image"
                                  onClick={()=>handleCardClick(item, index)}
                                  // effect="blur"
                                  // loading="lazy"
                                  // srcSet={`${image.url}?w=100 100w, ${image.url}?w=300 300w, ${image.url}?w=600 600w`}
                                  style={{ height: 220, width: 450 }}
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                  <div className="box">
                    <div className="row keep spaced short">
                      <div className="location">
                        {item.elements.ort === "" ? "nA" : item.elements.ort}
                      </div>
                      <div>
                        Ref:{" "}
                        {item.elements.objektnr_extern === ""
                          ? "nA"
                          : item.elements.objektnr_extern}
                      </div>
                    </div>
                    {/* <a
                      href="#"
                      className="link"
                      title="Link to property"
                    > */}
                    <div className="heading txt_3" style={{cursor:'pointer'}} onClick={() => handleCardClick(item, index)}>
                      {item.elements.objekttitel === ""
                        ? "Not Set"
                        : item.elements.objekttitel}
                    </div>
                    {/* </a> */}
                    <div className="price">
                      € {formatPrice(item.elements.kaufpreis)}
                    </div>
                    <div className="row grow keep details">
                      <div className="center_v">
                        <img
                          alt="Schlafzimmer"
                          className="sprites"
                          src={property_pic1}
                        />
                        <div>
                          {item.elements.anzahl_schlafzimmer === ""
                            ? "not set"
                            : parseInt(item.elements.anzahl_schlafzimmer)}
                        </div>
                      </div>
                      <div className="center_v">
                        <img
                          alt="Bäder"
                          className="sprites"
                          src={property_pic2}
                        />
                        <div>
                          {item.elements.anzahl_badezimmer === ""
                            ? "not set"
                            : parseInt(item.elements.anzahl_badezimmer)}
                        </div>
                      </div>
                      <div className="center_v">
                        <img
                          alt="Wohnfläche"
                          className="sprites"
                          src={property_pic3}
                        />
                        <div>
                          {item.elements.wohnflaeche === ""
                            ? "not set"
                            : `${parseInt(item.elements.wohnflaeche)}㎡`}
                        </div>
                      </div>
                      <div className="center_v">
                        <img
                          alt="Grundstück"
                          className="sprites"
                          src={property_pic4}
                        />
                        <div>
                          {item.elements.nutzflaeche === ""
                            ? "not set"
                            : `${parseInt(item.elements.nutzflaeche)}㎡`}
                        </div>
                      </div>
                    </div>
                    <div
                      className="row spaced clicks"
                      onClick={() => handleCardClick(item, index)}
                    >
                      <div></div>
                      <a className="c_1_1">{t("showEstate")}</a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertySearchItems;
