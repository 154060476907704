import React, { useState, useEffect } from "react";
import axios from "axios";
// import bannerimage from '../../assets/images/banner-image.png';
// import Brands from '../../components/Brands/Brands';
import Brands from "../../components/Brands/Brands";
import AddProperty from "../../components/AddProperty/AddProperty";
import OfferOfTheWeek from "../../components/OfferOfTheWeek/OfferOfTheWeek";
import TopRecommendations from "../../components/TopRecommendations/TopRecommendations";
import AboutUsInfo from "../../components/AboutUsInfo/AboutUsInfo";
import DiscoverAllproperties from "../../components/DiscoverAllproperties/DiscoverAllproperties";
import ImmobilieVerkaufen from "../../components/ImmobilieVerkaufen/ImmobilieVerkaufen";
import useStore from "../../Store/useStore";
import { useTranslation } from "react-i18next";
import { base_url } from "../../api/const";

const Home = () => {
  const { i18n, t } = useTranslation();
  const img_url = "https://www.sae-mallorca.com/propertywebsite";
  const [estates, setEstates] = useState([]);
  const [estatesWithImages, setEstatesWithImages] = useState([]);
  const [topRecommendedProperty, setTopRecommendedProperty] = useState([]);
  const [topRecommendedPropertyESP, setTopRecommendedPropertyESP] = useState(
    []
  );
  const [topRecommendedPropertyGER, setTopRecommendedPropertyGER] = useState(
    []
  );
  const [OfferOfTheWeekData, setOfferOfTheWeekData] = useState([]);
  const [OfferOfTheWeekDataGER, setOfferOfTheWeekDataGER] = useState([]);
  const [OfferOfTheWeekDataESP, setOfferOfTheWeekDataESP] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offerLoading, setOfferLoading] = useState(false);
  const selectLanguage = useStore((state) => state.selectLanguage);
  const setSelectLanguage = useStore((state) => state.setSelectLanguage);

  const [aboutUsImage, setAboutUsImage] = useState("");
  const [discoverAllPropertiesImage, setDiscoverAllPropertiesImage] =
    useState("");
  const [sellPropertyImage, setSellPropertyImage] = useState("");

  // States for address lines
  const [addressline1, setAddressLine1] = useState("");
  const [addressline2, setAddressLine2] = useState("");

  // States for banner texts (English, German, Spanish)
  const [aboutUsBannerTextEng, setAboutUsBannerTextEng] = useState("");
  const [aboutUsBannerTextGer, setAboutUsBannerTextGer] = useState("");
  const [aboutUsBannerTextSpa, setAboutUsBannerTextSpa] = useState("");

  const [
    discoverAllPropertiesBannerTextEng,
    setDiscoverAllPropertiesBannerTextEng,
  ] = useState("");
  const [
    discoverAllPropertiesBannerTextGer,
    setDiscoverAllPropertiesBannerTextGer,
  ] = useState("");
  const [
    discoverAllPropertiesBannerTextSpa,
    setDiscoverAllPropertiesBannerTextSpa,
  ] = useState("");

  const [sellPropertyBannerTextEng, setSellPropertyBannerTextEng] =
    useState("");
  const [sellPropertyBannerTextGer, setSellPropertyBannerTextGer] =
    useState("");
  const [sellPropertyBannerTextSpa, setSellPropertyBannerTextSpa] =
    useState("");

  const CryptoJS = require("crypto-js");

  const token = "01f760ab735718c491feab1b732d06f3";
  const secret =
    "47750a5bd3115a415fc2988dbc36c421c600efca69eba5cfebc660bc73178677";
  const timestamp = Math.floor(Date.now() / 1000);
  // const type= 'estate'
  function createHmac(token, secret, timestamp, type, actionId) {
    const fields = {
      timestamp: timestamp,
      token: token,
      resourcetype: type,
      actionid: actionId,
    };

    const hmac = CryptoJS.HmacSHA256(
      Object.values(fields).join(""),
      secret
    ).toString(CryptoJS.enc.Base64);

    return hmac;
  }

  const resourceType = "estate";
  const actionId = "urn:onoffice-de-ns:smart:2.5:smartml:action:read";

  const hmac = createHmac(token, secret, timestamp, resourceType, actionId);

  // console.log("timestamp:", timestamp);
  // console.log("hmac:", hmac);

  const fetchAllPropertyListEstateData = async () => {
    setOfferLoading(true);
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmac,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "objekt_der_woche",
                "objekt_des_tages",
                "objekttitel",
                "erstellt_am",
                "top_angebot",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche"
              ],
              sortby: { kaufpreis: "ASC", warmmiete: "ASC" },
              estatelanguage: "ENG",
              filter: {
                objekt_der_woche: [{ op: "IN", val: "1" }],
                reserviert: [{ op: "=", val: 0 }], // reserved
                verkauft: [{ op: "=", val: 0 }],  // sold
                veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
              }, // To filter specific ids
              addestatelanguage: true,
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          // "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // console.log(
      //   "INITIAL RESPONSE",
      //   response.data?.response?.results[0]?.data.records
      // );
      setEstates(response.data.data?.response?.results[0]?.data.records);
      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchAllPropertyListEstateImages(
        "ENG",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setOfferLoading(false);
    }
  };
  const fetchAllPropertyListEstateDataESP = async () => {
    setOfferLoading(true);
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmac,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "objekt_der_woche",
                "objekt_des_tages",
                "objekttitel",
                "erstellt_am",
                "top_angebot",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche"
              ],
              sortby: { kaufpreis: "ASC", warmmiete: "ASC" },
              estatelanguage: "ESP",
              filter: {
                objekt_der_woche: [{ op: "IN", val: "1" }],
                reserviert: [{ op: "=", val: 0 }],
                verkauft: [{ op: "=", val: 0 }],
                veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
              }, // To filter specific ids
              addestatelanguage: true,
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          // "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // // console.log(
      //   "INITIAL RESPONSE",
      //   response.data?.response?.results[0]?.data.records
      // );
      setEstates(response.data.data?.response?.results[0]?.data.records);
      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchAllPropertyListEstateImages(
        "ESP",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setOfferLoading(false);
    }
  };
  const fetchAllPropertyListEstateDataGER = async () => {
    setOfferLoading(true);
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmac,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "objekt_der_woche",
                "objekt_des_tages",
                "objekttitel",
                "erstellt_am",
                "top_angebot",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche"
              ],
              sortby: { kaufpreis: "ASC", warmmiete: "ASC" },
              estatelanguage: "",
              filter: {
                objekt_der_woche: [{ op: "IN", val: "1" }],
                reserviert: [{ op: "=", val: 0 }],
                verkauft: [{ op: "=", val: 0 }],
                veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
              }, // To filter specific ids
              addestatelanguage: true,
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          // "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      // console.log("INITIAL RESPONSE", response.data?.response);
      setEstates(response.data.data?.response?.results[0]?.data.records);
      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchAllPropertyListEstateImages(
        "",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setOfferLoading(false);
    }
  };

  const getTopRecommendedData = async () => {
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    // console.log("API_URL:", API_URL);
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmac,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "objekt_der_woche",
                "objekt_des_tages",
                "top_angebot",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche"
              ],
              sortby: { kaufpreis: "ASC", warmmiete: "ASC" },
              estatelanguage: "ENG",
              filter: {
                top_angebot: [{ op: "IN", val: 1 }],
                  veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
                  reserviert: [{ op: "=", val: 0 }],  // Reserved
                  verkauft: [{ op: "=", val: 0 }],  // Sold
              }, // To filter specific ids
              addestatelanguage: true,
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setLoading(false);
      // console.log(
      //   "INITIAL 2 RESPONSE",
      //   response.data.data?.response?.results[0]?.data.records
      // );

      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchTopRecommendedEstateImages(
        "ENG",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const getTopRecommendedDataESP = async () => {
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    // console.log("API_URL:", API_URL);
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmac,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "objekt_der_woche",
                "objekt_des_tages",
                "top_angebot",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche"
              ],
              sortby: { kaufpreis: "ASC", warmmiete: "ASC" },
              estatelanguage: "ESP",
              filter: {
                top_angebot: [{ op: "IN", val: 1 }],
                  veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
                  reserviert: [{ op: "=", val: 0 }],  // Reserved
                  verkauft: [{ op: "=", val: 0 }],  // Sold
              }, // To filter specific ids
              addestatelanguage: true,
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setLoading(false);
      // console.log(
      //   "INITIAL 2 RESPONSE",
      //   response.data.data?.response?.results[0]?.data.records
      // );

      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchTopRecommendedEstateImages(
        "ESP",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const getTopRecommendedDataGER = async () => {
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    // console.log("API_URL:", API_URL);
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: "urn:onoffice-de-ns:smart:2.5:smartml:action:read",
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmac,
            identifier: "",
            resourcetype: "estate",
            parameters: {
              data: [
                "Id", // Property id (**)
                "kaufpreis", // Price (**)
                "lage", // location / Address
                "objektart",
                "objekttyp",
                "objektnr_extern", // ref id (**)
                "objektnr_intern",
                "anzahl_badezimmer", // no of bathroom (**)
                "anzahl_zimmer", // no of rooms (**)
                "anzahl_schlafzimmer", // no of bedrooms (**)
                "nutzflaeche", // useable area(**)
                "verfuegbar", // Available Area
                "wohnflaeche", // Living space (**)
                "gesamtflaeche", // Total area
                "ort", // Property short location (**)
                "objekttitel", // Property title (**)
                "erstellt_am", // created date (**)
                "objekt_der_woche",
                "objekt_des_tages",
                "top_angebot",
                "ausstatt_beschr", // Furnishing
                "objektbeschreibung", // property description
                "sonstige_angaben", // other info
                "heizungsart", // heating type
                "befeuerung", // firing
                "breitengrad", // lat
                "laengengrad", // long
                "terrasse", // Terrace
                "balkon", // Balcony
                "warmwasserEnthalten", // hot water
                "toiletten", // toilet
                "grundstuecksflaeche"
              ],
              sortby: { kaufpreis: "ASC", warmmiete: "ASC" },

              filter: {
                top_angebot: [{ op: "IN", val: 1 }],
                  veroeffentlichen: [{ op: "IN", val: "1" }],  // Publish
                  reserviert: [{ op: "=", val: 0 }],  // Reserved
                  verkauft: [{ op: "=", val: 0 }],  // Sold
              }, // To filter specific ids
              addestatelanguage: true,
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setLoading(false);
      // console.log(
      //   "INITIAL 2 RESPONSE",
      //   response.data.data?.response?.results[0]?.data.records
      // );

      let IDS = response.data.data?.response?.results[0]?.data?.records.map(
        (item) => parseInt(item.elements.Id)
      );
      // // console.log("ID ====", IDS);
      fetchTopRecommendedEstateImages(
        "",
        IDS,
        response.data.data?.response?.results[0]?.data.records
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const resourceTypeImages = "estatepictures";
  const actionIdImages = "urn:onoffice-de-ns:smart:2.5:smartml:action:get";

  const hmacImages = createHmac(
    token,
    secret,
    timestamp,
    resourceTypeImages,
    actionIdImages
  );
  // console.log("hmac Image:", hmacImages);

  const fetchAllPropertyListEstateImages = async (lang, ids, content) => {
    // console.log("OFFER IDS", ids);
    setOfferLoading(true);
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: actionIdImages,
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmacImages,
            identifier: "",
            resourcetype: resourceTypeImages,
            parameters: {
              estateids: ids,
              categories: [ "Titelbild", "Foto"],
              size: "original",
              language: "",
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      // console.log(
      //   "Images",
      //   response.data
      // );
      if (lang === "ENG") {
        let DATA = content.map((estate) => {
          const matchingFiles =
            response.data.data?.response?.results[0]?.data.records.flatMap(
              (file) =>
                file.elements.filter((el) => el.estateid == estate.elements.Id)
            );
          const titles = matchingFiles.map((file) => file.title);
          const urls = matchingFiles.map((file) => ({ url: file.url }));
          return {
            ...estate,
            elements: {
              ...estate.elements,
              titles,
              urls,
            },
          };
        });
        setOfferOfTheWeekData(DATA);
        setOfferLoading(false);
        // console.log(DATA, "ENG");
      } else if (lang === "ESP") {
        let DATA = content.map((estate) => {
          const matchingFiles =
            response.data.data?.response?.results[0]?.data.records.flatMap(
              (file) =>
                file.elements.filter((el) => el.estateid == estate.elements.Id)
            );
          const titles = matchingFiles.map((file) => file.title);
          const urls = matchingFiles.map((file) => ({ url: file.url }));
          return {
            ...estate,
            elements: {
              ...estate.elements,
              titles,
              urls,
            },
          };
        });
        setOfferOfTheWeekDataESP(DATA);
        setOfferLoading(false);
        // console.log("Offer Property ESP Image", DATA);
      } else if (lang === "") {
        let DATA = content.map((estate) => {
          const matchingFiles =
            response.data.data?.response?.results[0]?.data.records.flatMap(
              (file) =>
                file.elements.filter((el) => el.estateid == estate.elements.Id)
            );
          const titles = matchingFiles.map((file) => file.title);
          const urls = matchingFiles.map((file) => ({ url: file.url }));
          return {
            ...estate,
            elements: {
              ...estate.elements,
              titles,
              urls,
            },
          };
        });
        setOfferOfTheWeekDataGER(DATA);
        setOfferLoading(false);
        // console.log("GER DATA", DATA);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setOfferLoading(false);
    }
  };

  const fetchTopRecommendedEstateImages = async (lang, ids, content) => {
    // console.log(lang);
    setLoading(true);
    const API_URL =
      "https://www.sae-mallorca.com/propertywebsite/api/proxy";
    const requestData = {
      token: "01f760ab735718c491feab1b732d06f3",
      request: {
        actions: [
          {
            actionid: actionIdImages,
            resourceid: "",
            timestamp: timestamp,
            hmac_version: "2",
            hmac: hmacImages,
            identifier: "",
            resourcetype: resourceTypeImages,
            parameters: {
              estateids: ids,
              categories: [ "Titelbild", "Foto"],
              size: "400X700",
              // language: "ENG",
            },
          },
        ],
      },
    };

    try {
      const response = await axios.post(API_URL, requestData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setLoading(false);
      // console.log("Images", response);
      if (lang === "ENG") {
        let DATA = content.map((estate) => {
          const matchingFiles =
            response.data.data?.response?.results[0]?.data.records.flatMap(
              (file) =>
                file.elements.filter((el) => el.estateid == estate.elements.Id)
            );
          const titles = matchingFiles.map((file) => file.title);
          const urls = matchingFiles.map((file) => ({ url: file.url }));
          return {
            ...estate,
            elements: {
              ...estate.elements,
              titles,
              urls,
            },
          };
        });
        // console.log("ENG DATA", DATA);

        setTopRecommendedProperty(DATA);
      } else if (lang === "ESP") {
        let DATA = content.map((estate) => {
          const matchingFiles =
            response.data.data?.response?.results[0]?.data.records.flatMap(
              (file) =>
                file.elements.filter((el) => el.estateid == estate.elements.Id)
            );
          const titles = matchingFiles.map((file) => file.title);
          const urls = matchingFiles.map((file) => ({ url: file.url }));
          return {
            ...estate,
            elements: {
              ...estate.elements,
              titles,
              urls,
            },
          };
        });
        // console.log("ESP DATA", DATA);

        setTopRecommendedPropertyESP(DATA);
      } else if (lang === "") {
        let DATA = content.map((estate) => {
          const matchingFiles =
            response.data.data?.response?.results[0]?.data.records.flatMap(
              (file) =>
                file.elements.filter((el) => el.estateid == estate.elements.Id)
            );
          const titles = matchingFiles.map((file) => file.title);
          const urls = matchingFiles.map((file) => ({ url: file.url }));
          return {
            ...estate,
            elements: {
              ...estate.elements,
              titles,
              urls,
            },
          };
        });
        setTopRecommendedPropertyGER(DATA);
        // console.log("GER DATA", DATA);
      }
      // lang === "ENG" ?
      // setTopRecommendedProperty(DATA)
      // : lang === "ESP" ?
      // setTopRecommendedPropertyESP(DATA)
      // :
      // setTopRecommendedPropertyGER(DATA)
      // // console.log("DAta with Image", DATA);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const imgURL = "https://www.sae-mallorca.com/propertywebsite";
  const tokens =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  const homeContent = async () => {
    let lang = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/home-page-setting?populate=*`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data.attributes);

        const { attributes } = response.data.data;
        const localizations = attributes.localizations.data;
        // console.log(imgURL + attributes.about_us_image.data.attributes.url);
        setAboutUsImage(imgURL + attributes.about_us_image.data.attributes.url);
        setDiscoverAllPropertiesImage(
          imgURL + attributes.discover_all_properties_image.data.attributes.url
        );
        setSellPropertyImage(
          imgURL + attributes.sell_property_image.data.attributes.url
        );

        setAddressLine1(attributes.addressline1);
        setAddressLine2(attributes.addressline2);

        setAboutUsBannerTextEng(attributes.about_us_banner_text);
        setDiscoverAllPropertiesBannerTextEng(
          attributes.discover_all_properties_banner_text
        );
        setSellPropertyBannerTextEng(attributes.sell_property_banner_text);

        localizations.forEach((loc) => {
          switch (loc.attributes.locale) {
            case "de":
              setAboutUsBannerTextGer(loc.attributes.about_us_banner_text);
              setDiscoverAllPropertiesBannerTextGer(
                loc.attributes.discover_all_properties_banner_text
              );
              setSellPropertyBannerTextGer(
                loc.attributes.sell_property_banner_text
              );
              break;
            case "es":
              setAboutUsBannerTextSpa(loc.attributes.about_us_banner_text);
              setDiscoverAllPropertiesBannerTextSpa(
                loc.attributes.discover_all_properties_banner_text
              );
              setSellPropertyBannerTextSpa(
                loc.attributes.sell_property_banner_text
              );
              break;
            default:
              break;
          }
        });
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };
  // const resourceTypeAddress= 'address';
  // const actionIdAddress = 'urn:onoffice-de-ns:smart:2.5:smartml:action:read';

  // const hmacAddress = createHmac(token, secret, timestamp, resourceTypeAddress, actionIdAddress);
  // // console.log("hmac Address:", hmacAddress)

  useEffect(() => {
    fetchAllPropertyListEstateData();
    fetchAllPropertyListEstateDataGER();
    fetchAllPropertyListEstateDataESP();
    getTopRecommendedDataGER();
    getTopRecommendedData();
    getTopRecommendedDataESP();
    homeContent();
  }, []);

  return (
    <>
      <Brands />
      {/* <AddProperty /> */}
      <OfferOfTheWeek
        engData={OfferOfTheWeekData}
        spaData={OfferOfTheWeekDataESP}
        gerData={OfferOfTheWeekDataGER}
        loading={offerLoading}
      />
      <TopRecommendations
        engData={topRecommendedProperty}
        spaData={topRecommendedPropertyESP}
        gerData={topRecommendedPropertyGER}
        loading={loading}
      />
      <AboutUsInfo
        engData={aboutUsBannerTextEng}
        spaData={aboutUsBannerTextSpa}
        gerData={aboutUsBannerTextGer}
        img={aboutUsImage}
      />
      <DiscoverAllproperties
        engData={discoverAllPropertiesBannerTextEng}
        spaData={discoverAllPropertiesBannerTextSpa}
        gerData={discoverAllPropertiesBannerTextGer}
        img={discoverAllPropertiesImage}
      />
      <ImmobilieVerkaufen
        engData={sellPropertyBannerTextEng}
        spaData={sellPropertyBannerTextSpa}
        gerData={sellPropertyBannerTextGer}
        img={sellPropertyImage}
      />
      <AddProperty />
    </>
  );
};

export default Home;
