import React, { useState, useRef } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import banneradd1 from "../../assets/img/banneradd1.jpg";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import useStore from "../../Store/useStore";
const Testimonial = ({ headerImg, img }) => {
  const [visibleComponent, setVisibleComponent] = useState(null);
  const setFilters = useStore((state) => state.setFilters);
  const page = useStore((state) => state.page);
  const setPage = useStore((state) =>state.setPage);
  const [refNumber, setRefNumber] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [noOfBed, setNoOfBed] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [addedLocation, setAddedLocation] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [locationAdded, setLocationAdded] = useState(false);
  const history = useHistory();
  const actionRef = React.useRef(null);
  const { t } = useTranslation();
  const handleShowComponent = (div) => {
    setVisibleComponent((prevDiv) => (prevDiv === div ? null : div));
  };

  const handleHideComponent = () => {
    setVisibleComponent(null);
  };

  const locations = [
    { id: "1", name: "Palma" },
    { id: "2", name: "Santa Ponça" },
    { id: "4", name: "Puig De Ros" },
    { id: "5", name: "Llucmajor" },
    { id: "6", name: "Sa Torre" },
    { id: "8", name: "Calvià / Santa Ponça" },
    { id: "11", name: "Palma de Mallorca" },
    { id: "14", name: "Llucmajor / s'Arenal" },
    { id: "18", name: "Esporles" },
    { id: "24", name: "Calvià / el Toro" },
    { id: "25", name: "Llucmajor / Son Verí Nou" },
    { id: "27", name: "Lloseta" },
    { id: "30", name: "Calvià / Costa d'en Blanes" },
    { id: "31", name: "Calvià" },
    { id: "41", name: "Calvià / Palmanova" },
    { id: "45", name: "Marratxí" },
    { id: "51", name: "Capdepera / Font de sa Cala" },
    { id: "57", name: "Badia Blava" },
    { id: "58", name: "S'Arenal" },
    { id: "64", name: "Ses palmeres" },
    { id: "65", name: "Can Pastilla" },
    { id: "67", name: "Cala Pi" },
    { id: "73", name: "Porto Cristo" },
    { id: "74", name: "Arenal" },
    { id: "75", name: "Tolleric" },
    { id: "77", name: "Calvià / Costa de la Calma" },
    { id: "81", name: "Cala Mendia" },
    { id: "82", name: "Bendinat" },
    { id: "84", name: "Son Verí Nou" },
    { id: "89", name: "Santa Margalida" },
    { id: "96", name: "Sant Agustin" },
    { id: "98", name: "santa ponsa" },
    { id: "101", name: "Arta" },
    { id: "107", name: "Cala Ratjada" },
    { id: "122", name: "Cala d'Or" },
    { id: "124", name: "Sant Joan" },
    { id: "126", name: "Platja de Palma" },
    { id: "134", name: "Paguera" },
    { id: "145", name: "Son Vida" },
    { id: "147", name: "Torrenova" },
    { id: "149", name: "Bunyola" },
    { id: "150", name: "Portixol" },
    { id: "155", name: "Son Sardina" },
    { id: "157", name: "Bellavista" },
    { id: "158", name: "Badia Gran" },
    { id: "161", name: "Can Picafort" },
    { id: "163", name: "Inca" },
    { id: "164", name: "Son Veri" },
    { id: "166", name: "Sa Coma" },
    { id: "169", name: "Sa Rapita" },
    { id: "171", name: "Costa d'en Blanes" },
    { id: "176", name: "Molinar" },
    { id: "178", name: "andratx" },
    { id: "181", name: "port-d-andratx" },
    { id: "184", name: "sol-de-mallorca" },
    { id: "187", name: "montuiri" },
    { id: "189", name: "es-pelats" },
    { id: "103", name: "Mallorca Süd" },
    { id: "104", name: "Mallorca Süd West" },
    { id: "105", name: "Mallorca Zentrum" },
    { id: "106", name: "Mallorca Palma" },
    { id: "113", name: "Mallorca Nord West" },
    { id: "114", name: "Mallorca Ost" },
    { id: "115", name: "Mallorca Nord" },
    { id: "116", name: "Mallorca West" },
    { id: "117", name: "Mallorca Süd Ost" },
  ];

  const locationMap = locations.reduce((acc, location) => {
    acc[location.id] = location.name;
    return acc;
  }, {});

  const parentLocations = {
    103: ["4", "5", "6", "25", "57", "64", "67", "75", "84", "157", "158"],
    104: [
      "2",
      "8",
      "24",
      "30",
      "31",
      "41",
      "77",
      "82",
      "98",
      "134",
      "147",
      "166",
      "171",
      "178",
      "181",
      "184",
    ],
    105: ["27", "124", "163", "187"],
    106: [
      "1",
      "11",
      "14",
      "45",
      "58",
      "65",
      "74",
      "96",
      "126",
      "145",
      "150",
      "155",
      "164",
      "176",
    ],
    113: [""],
    114: ["122", "73", "81"],
    115: ["51", "89", "101", "107", "161", "189"],
    116: ["18", "149"],
    117: ["169"],
  };

  const handleMaxPriceChange = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ""), 10) || "";
    setMaxPrice(value);
  };

  const handleMinPriceChange = (e) => {
    const value = parseInt(e.target.value.replace(/\D/g, ""), 10) || "";
    setMinPrice(value);
  };

  const handleNoOfBedChange = (event) => {
    const { value } = event.target;

    // Allow empty value for deletion
    if (value === "") {
      setNoOfBed("");
      return;
    }

    // Convert value to a number and ensure it's non-negative
    const numericValue = Number(value);
    if (numericValue >= 0 && !isNaN(numericValue)) {
      const values = parseInt(value.replace(/\D/g, ""), 10) || "";
      setNoOfBed(values); // Set the value as a string to keep leading zeros if needed
    }
    // setNoOfBed(event.target.value);
  };

  const handleRefNo = (e) => {
    setRefNumber(e.target.value);
    setInputValue("");
  };
  const handlePropertyTypeChange = (event) => {
    const selectedType = event.target.value;
    if (event.target.checked) {
      setPropertyType([...propertyType, selectedType]);
    } else {
      setPropertyType(propertyType.filter((type) => type !== selectedType));
    }
  };

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    const locationName = event.target.getAttribute("data-location-name");
    const isChecked = event.target.checked;

    if (parentLocations[selectedLocation]) {
      // Handle parent location
      if (isChecked) {
        // Check all children
        const newSelectedLocations = [
          ...selectedLocations,
          { id: selectedLocation, name: findLocationName(selectedLocation) },
          ...parentLocations[selectedLocation].map((childId) => ({
            id: childId,
            name: findLocationName(childId),
          })),
        ];
        setSelectedLocations([...new Set(newSelectedLocations)]);
      } else {
        // Uncheck all children
        const newSelectedLocations = selectedLocations.filter(
          (location) =>
            location.id !== selectedLocation &&
            !parentLocations[selectedLocation].includes(location.id)
        );
        setSelectedLocations(newSelectedLocations);
      }
    } else {
      // Handle child location
      if (isChecked) {
        setSelectedLocations([
          ...selectedLocations,
          { id: selectedLocation, name: locationName },
        ]);
      } else {
        setSelectedLocations(
          selectedLocations.filter(
            (location) => location.id !== selectedLocation
          )
        );
      }
    }
  };

  // Helper function to find location name by id
  const findLocationName = (id) => {
    const location = locations.find((loc) => loc.id === id);
    return location ? location.name : "";
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    if (event.target.value.trim() !== "" && selectedLocations.length > 0) {
      // Alert when there are selected checkboxes and search input is not empty
      alert(t("alertText"));

      // Clear selected checkboxes
      setSelectedLocations([]);
    }
  };

  const handleAddLocation = async () => {
    const newLocation = inputValue.trim();
    if (!newLocation) return;

    // Check if location is already selected
    if (!selectedLocations.some((location) => location.name === newLocation)) {
      setSelectedLocations([
        ...selectedLocations,
        { id: newLocation, name: newLocation },
      ]);
      // setAddedLocation([
      //   ...addedLocation,
      //   { id: newLocation, name: newLocation },
      // ])
      setLocationAdded(true);
      filterData([{ id: newLocation, name: newLocation }], true); // Mark that a location has been added
    }

    // Clear the input field
    setInputValue("");
  };

  const filterData = (locat, sele) => {
    // console.log("refNumber", refNumber);
    // console.log("maxPrice", maxPrice);
    // console.log("minPrice", minPrice);
    // console.log("selectedLocations", selectedLocations);
    // console.log("propertyType", propertyType);
    // console.log("noOfBed", noOfBed);
    // console.log("locations", locat);
    // // console.log("showFilters", showFilters);
    // if(sele){
    //   setTimeout(() => {
    //     history.push({ pathname:'/propertysearch',
    //       state: {
    //         filteredLocations:  locat,
    //       }
    //       });
    //   }, 500);
    //   // console.log('hhh')
    // }else{
    setTimeout(() => {
      setFilters({
        refNo: refNumber,
        minPrice: minPrice,
        maxPrice: maxPrice,
        selectedLocations: sele ? locat : selectedLocations,
        propertyType: propertyType,
        noOfBed: noOfBed,
      });
      setPage(1)
      history.push({
        pathname: "/propertysearch",
        // state: {
        //   refNumber: refNumber,
        //   maximumPrice: maxPrice,
        //   minimumPrice: minPrice,
        //   filteredLocations: sele ? locat : selectedLocations,
        //   typeOfProperty: propertyType,
        //   numberOfBed: noOfBed,
        // },
      });
    }, 500);
    // }
    // setLocationAdded(false)
  };
  const handleEnterPressSearch = (e) => {
    if (e.key === "Enter" || e.code === "Enter" || e.code === "NumpadEnter") {
      actionRef.current?.click();
    }
  };

  return (
    <section className="brands">
      <div className="cm_1">
        <div className="box">
          <div className="pos">
            <div className="auto c_3">
              <div className="c_form_loading">
                <div className="row group center_vh">
                  <div className="material-textfield">
                    <input
                      placeholder={t("refNumber")}
                      type="text"
                      value={refNumber}
                      onKeyDown={handleEnterPressSearch}
                      onChange={handleRefNo}
                      onClick={() => {
                        setVisibleComponent(null);
                        setInputValue("");
                      }}
                    />
                    <label>{t("refNumber")}</label>
                  </div>
                  <div className="c_virtual c_field">
                    <a
                      href="javascript:void(0)"
                      className="row keep center_vh"
                      onClick={() => {
                        setInputValue("");
                        handleShowComponent("Component1");
                      }}
                    >
                      <span className="label">{t("price")}</span>
                      <i className="down c_arrows"></i>
                    </a>
                  </div>
                  <div className="c_virtual c_field">
                    <a
                      href="javascript:void(0)"
                      className="row keep center_vh"
                      onClick={() => {
                        setInputValue("");
                        handleShowComponent("Component2");
                      }}
                    >
                      <span className="label" style={{ fontSize: 15 }}>
                        {t("propertyType")}
                      </span>
                      <i className="down c_arrows"></i>
                    </a>
                  </div>
                  <div className="c_virtual c_field">
                    <a
                      href="javascript:void(0)"
                      className="row keep center_vh"
                      onClick={() => {
                        setInputValue("");
                        handleShowComponent("Component3");
                      }}
                    >
                      <span className="label">{t("location")}</span>
                      <i className="down c_arrows"></i>
                    </a>
                  </div>
                  <div className="material-textfield">
                    <input
                      id="data_estates__bedroom"
                      placeholder={t("bedrooms")}
                      type="number"
                      onKeyDown={handleEnterPressSearch}
                      value={noOfBed}
                      onChange={handleNoOfBedChange}
                      onClick={() => {
                        setVisibleComponent(null);
                        setInputValue("");
                      }}
                    />
                    <label>{t("bedrooms")}</label>
                  </div>
                  {/* {inputValue.trim() === '' && */}
                  <div
                    className="action_button"
                    ref={actionRef}
                    onClick={() => {
                      const newLocation = inputValue.trim();
                      if (newLocation) {
                        handleAddLocation();
                      } else {
                        filterData();
                      }
                    }}
                  >
                    <a className="button index_0 " ref={actionRef}>
                      {t("searchButton")}
                    </a>
                  </div>
                  {/* } */}
                </div>

                {visibleComponent === "Component1" && (
                  <div className="virtual group from_optview1">
                    <div className="row center_v c_form_range_inputs">
                      <div className="inp_row_cont wrapper-row-content">
                        <div className="material-textfield">
                          <input
                            placeholder={t("priceFrom")}
                            type="number"
                            onKeyDown={handleEnterPressSearch}
                            value={minPrice}
                            onChange={handleMinPriceChange}
                          />
                          <label>{t("priceFrom")}</label>
                        </div>
                        <div className="material-textfield">
                          <input
                            placeholder={t("priceTo")}
                            type="number"
                            value={maxPrice}
                            onKeyDown={handleEnterPressSearch}
                            onChange={handleMaxPriceChange}
                          />
                          <label>{t("priceTo")}</label>
                        </div>
                        {/* <div className="def_btn_opt list-name-content">
                          <button onClick={filterData}>
                            {t("useSelection")}
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )}

                {visibleComponent === "Component2" && (
                  <div className="virtual group from_optview2">
                    <fieldset className="c_form_checkbox_list c_field_group">
                      <legend>{t("propertyType")}</legend>
                      <div className="list">
                        <div className="c_form_checkbox c_field">
                          <label data-value="1" data-id="id_0">
                            <input
                              type="checkbox"
                              id="checkbox_id_0"
                              value="haus"
                              checked={propertyType.includes("haus")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("house")}
                          </label>
                        </div>
                        <div className="c_form_checkbox c_field">
                          <label data-value="2" data-id="id_1">
                            <input
                              type="checkbox"
                              id="checkbox_id_1"
                              value="wohnung"
                              checked={propertyType.includes("wohnung")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("apartment")}
                          </label>
                        </div>
                        <div className="c_form_checkbox c_field">
                          <label data-value="4" data-id="id_2">
                            <input
                              type="checkbox"
                              id="checkbox_id_2"
                              value="grundstueck"
                              checked={propertyType.includes("grundstueck")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("land")}
                          </label>
                        </div>
                        <div className="c_form_checkbox c_field">
                          <label data-value="5" data-id="id_3">
                            <input
                              type="checkbox"
                              id="checkbox_id_3"
                              value="gastgewerbe"
                              checked={propertyType.includes("gastgewerbe")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("commercial")}
                          </label>
                        </div>
                        <div className="c_form_checkbox c_field">
                          <label data-value="6" data-id="id_4">
                            <input
                              type="checkbox"
                              id="checkbox_id_4"
                              value="einzelhandel"
                              checked={propertyType.includes("einzelhandel")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("retail")}
                          </label>
                        </div>
                        <div className="c_form_checkbox c_field">
                          <label data-value="7" data-id="id_5">
                            <input
                              type="checkbox"
                              id="checkbox_id_5"
                              value="neubau"
                              checked={propertyType.includes("neubau")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("newBuild")}
                          </label>
                        </div>
                        <div className="c_form_checkbox c_field">
                          <label data-value="8" data-id="id_6">
                            <input
                              type="checkbox"
                              id="checkbox_id_6"
                              value="sonstige"
                              checked={propertyType.includes("sonstige")}
                              onChange={handlePropertyTypeChange}
                            />
                            {t("other")}
                          </label>
                        </div>
                      </div>
                      <span></span>
                    </fieldset>
                  </div>
                )}

                {visibleComponent === "Component3" && (
                  <div className="virtual group open from_optview3">
                    <fieldset className="c_form_checkbox_list_tree c_field_group">
                      <legend>{t("location")}</legend>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          marginBottom: 2,
                          justifyContent: "space-between",
                          flexDirection: { xs: "column", sm: "row" },
                          // border : "1px solid #aaa",
                          // borderRadius: "5%",
                        }}
                      >
                        <div
                          classname="search-container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "50px",
                            padding: "5px 15px",
                            width: "100%",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                            transition: "all 0.3s ease"
                          }}
                        >
                          <SearchIcon
                            style={{ marginRight: "5px", color: "#aaa" }}
                          />
                          <input
                            type="text"
                            placeholder={t("searchLocation")}
                            onKeyDown={handleEnterPressSearch}
                            className="search-input"
                            onChange={handleInputChange}
                            style={{ border: "none", fontSize: "16px" }}
                          />
                        </div>
                        {/* {inputValue.trim() !== '' &&
                  <div
              // className="action_button"
              style={{border: "1px solid #ffF",
                // whiteSpace: "nowrap",
                boxShadow: "rgba(0, 0, 1, 0.2) 0px 7px 29px 0px",
                padding: "9.9px 36px",
                padding: "0.55rem 2rem",
                backgroundColor: "#6c757d",
                display: "block",
                textAlign: "center",
                // color:"#fff",
                fontWeight: 600,}}
              onClick={handleAddLocation}
            >
              <a href="#" style={{color:'#fff', fontWeight: 600,}}>
                {t("searchButton")}
              </a>
            </div>
} */}
                        {/* <Button
                    variant="contained"
                    // color="#000"
                    onClick={handleAddLocation}
                    sx={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      backgroundColor: "#000",
                      color: "#fff",
                      textTransform: "capitalize",
                      width: { xs: '100%', sm: '20%' }, 
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    {t('searchButton')}
                  </Button> */}
                      </Box>
                      <div>
                        <legend style={{ top: -5, fontSize: "20px" }}>
                          {t("locHeader")}{" "}
                        </legend>
                      </div>
                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="103" data-id="id_103">
                            <input
                              type="checkbox"
                              id="checkbox_id_103"
                              data-value="103"
                              data-location-name="Mallorca Süd"
                              value="103"
                              checked={selectedLocations.some(
                                (location) => location.id === "103"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Süd")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["103"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="104" data-id="id_104">
                            <input
                              type="checkbox"
                              id="checkbox_id_104"
                              data-value="104"
                              data-location-name="Mallorca Süd"
                              value="104"
                              checked={selectedLocations.some(
                                (location) => location.id === "104"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Süd West")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["104"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="105" data-id="id_105">
                            <input
                              type="checkbox"
                              id="checkbox_id_105"
                              data-value="105"
                              data-location-name="Mallorca Süd"
                              value="105"
                              checked={selectedLocations.some(
                                (location) => location.id === "105"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Zentrum")}
                          </label>
                        </div>

                        <div className="list row start wrap">
                          {parentLocations["105"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="106" data-id="id_106">
                            <input
                              type="checkbox"
                              id="checkbox_id_106"
                              data-value="106"
                              data-location-name="Mallorca Süd"
                              value="106"
                              checked={selectedLocations.some(
                                (location) => location.id === "106"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Palma")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["106"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="l2">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="113" data-id="id_113">
                            <input
                              type="checkbox"
                              id="checkbox_id_113"
                              data-value="113"
                              data-location-name="Mallorca Süd"
                              value="113"
                              checked={selectedLocations.some(
                                (location) => location.id === "113"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Nord West")}
                          </label>
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="114" data-id="id_114">
                            <input
                              type="checkbox"
                              id="checkbox_id_114"
                              data-value="114"
                              data-location-name="Mallorca Süd"
                              value="114"
                              checked={selectedLocations.some(
                                (location) => location.id === "114"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Ost")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["114"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="115" data-id="id_115">
                            <input
                              type="checkbox"
                              id="checkbox_id_115"
                              data-value="115"
                              data-location-name="Mallorca Süd"
                              value="115"
                              checked={selectedLocations.some(
                                (location) => location.id === "115"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Nord")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["115"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="116" data-id="id_116">
                            <input
                              type="checkbox"
                              id="checkbox_id_116"
                              data-value="116"
                              data-location-name="Mallorca Süd"
                              value="116"
                              checked={selectedLocations.some(
                                (location) => location.id === "116"
                              )}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca West")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["116"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    data-location-name={locationMap[childId]}
                                    checked={selectedLocations.some(
                                      (location) => location.id === childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {locationMap[childId]}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="has_children">
                        <div className="c_form_checkbox c_field">
                          <label className="" data-value="117" data-id="id_117">
                            <input
                              type="checkbox"
                              id={"checkbox_id_117"}
                              value="117"
                              checked={selectedLocations.includes("117")}
                              onChange={(event) => {
                                if (inputValue.trim() !== "") {
                                  alert(t("alertText"));
                                } else {
                                  handleLocationChange(event);
                                }
                              }}
                              // disabled={inputValue.trim() !== ''}
                            />
                            {t("Mallorca Süd Ost")}
                          </label>
                        </div>
                        <div className="list row start wrap">
                          {parentLocations["117"].map((childId) => (
                            <div key={childId} className="l2">
                              <div className="c_form_checkbox c_field">
                                <label
                                  className=""
                                  data-value={childId}
                                  data-id={`id_${childId}`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`checkbox_id_${childId}`}
                                    value={childId}
                                    checked={selectedLocations.includes(
                                      childId
                                    )}
                                    onChange={(event) => {
                                      if (inputValue.trim() !== "") {
                                        alert(t("alertText"));
                                      } else {
                                        handleLocationChange(event);
                                      }
                                    }}
                                    // disabled={inputValue.trim() !== ''}
                                  />
                                  {childId === "169" && "Sa Rapita"}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <span></span>
                      <div></div>
                    </fieldset>
                  </div>
                )}

                <div className="row spaced c_form_search_filters_selected">
                  <div className="row keep wrap filters">
                    <div className="tag">
                      <a href="#" className="row keep center_v">
                        <img
                          className="c_icon"
                          src="https://www.sae-mallorca.com/assets/img/sprites/close-circle-outline.svg"
                          alt="Icon close-circle-outline"
                        />
                        <div>{t("selectedFilter")}</div>
                      </a>
                    </div>
                  </div>
                  <div className="remove">
                    <div className="tag">
                      <a href="#" className="row keep center_v">
                        <img
                          className="c_icon"
                          src="https://www.sae-mallorca.com/assets/img/sprites/close-circle-outline.svg"
                          alt="Icon close-circle-outline"
                        />
                        {t("clearFilters")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src={headerImg ? img : banneradd1}
          alt=""
          className="banner_pic"
          title=""
        />
      </div>
    </section>
  );
};

export default Testimonial;
