import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { base_url } from "./../../api/const";
import toast from "react-hot-toast";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useLocation, useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};

const ContactUs = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
    };
    handleResize(); // Set initial state
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const Alert = React.forwardRef((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const { i18n, t } = useTranslation();
  const img_url = "https://www.sae-mallorca.com/propertywebsite";
  const [mail, setMail] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [company, setCompany] = useState("");
  const [ph1, setPh1] = useState("");
  const [ph2, setPh2] = useState("");
  const [tele, setTele] = useState("");
  const [mapUrl, setMapUrl] = useState("");
  const [content, setContent] = useState([]);

  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [selectOption, setSelectOption] = useState("");
  const [subject, setSubject] = useState("");
  const [agreed, setAgreed] = useState(false);
  // Handle form submission
  const [error, setError] = useState({ field: "", message: "" });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const history = useHistory();
  const [menuOpen, setMenuOpen] = useState(false);
  const selectRef = useRef(null); // Ref for the Select component

  // Scroll event handler
  const handleScroll = () => {
    if (menuOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isVisible) {
        setMenuOpen(false); // Close the menu if it goes out of view
      }
    }
  };
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);

  const initializeMap = () => {
    setTimeout(() => {
      if (mapRef.current && !mapInstanceRef.current) {
        mapInstanceRef.current = L.map(mapRef.current).setView(
          [39.5712, 2.6408],
          13
        );

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: "© OpenStreetMap contributors",
        }).addTo(mapInstanceRef.current);

        L.marker([39.5712, 2.6408]).addTo(mapInstanceRef.current).openPopup();

        setMapReady(true);
      }
    }, 100); // Adjust the timeout duration if necessary
  };
  useEffect(() => {
    if (menuOpen) {
      // Add scroll event listener when the menu is open
      window.addEventListener("scroll", handleScroll);
    } else {
      // Remove scroll event listener when the menu is closed
      window.removeEventListener("scroll", handleScroll);
    }

    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuOpen]);
  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";

  const handleChange = (event) => {
    setSelectOption(event.target.value);
  };

  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/contact?locale=${lang}&populate=*`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data, "response=================");
        // setContent(response.data.data);
        setMail(response.data.data.attributes.email);
        setSubTitle(response.data.data.attributes.subtitle[0].children[0].text);
        setAddress1(response.data.data.attributes.address1);
        setAddress2(response.data.data.attributes.address2);
        setCompany(response.data.data.attributes.company);
        setPh1(response.data.data.attributes.mobile1);
        setPh2(response.data.data.attributes.mobile2);
        setTele(response.data.data.attributes.telephone);
        setMapUrl(response.data.data.attributes.map);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset error state before validation
    setError({ field: "", message: "" });

    // Validation checks
    if (!name) {
      setError({ field: "name", message: t("nameRequired") });
    } else if (!email) {
      setError({ field: "email", message: t("emailRequired") });
    } else if (!validateEmail(email)) {
      setError({
        field: "email",
        message: t("invalidEmail"),
      });
    } else if (!phone) {
      setError({ field: "phone", message: t("phoneRequired") });
    } else if (!selectOption) {
      setError({ field: "selectOption", message: t("requestTypeRequired") });
    } else if (!subject) {
      setError({ field: "subject", message: t("subjectRequired") });
    } else if (!agreed) {
      setError({
        field: "agreed",
        message: t("privacyPolicyAgreement"),
      });
    } else {
      // console.log("Name:", name);
      // console.log("Email:", email);
      // console.log("Phone:", phone);
      // console.log("Request Type:", selectOption);
      // console.log("Subject:", subject);
      // console.log("Agreed to Privacy Policy:", agreed);
      setError({ field: "", message: "" });
      submitBookings(name, email, phone, subject, selectOption);
    }
    if (error.field) {
      setSnackbarOpen(true);
    }
  };
  const tokens =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  const submitBookings = async (name, email, phone, subject, selectOption) => {
    const API_URL = "https://www.sae-mallorca.com/propertywebsite/api/messages";
    let reqData = {
      data: {
        name: name,
        email: email,
        phone: phone,
        message: subject,
        reason: selectOption,
      },
    };
    try {
      const response = await axios.post(API_URL, reqData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${tokens}`,
        },
      });
      // console.log("RESPONSE ====>", response);
      if (response.status == 200) {
        toast.success(t("thankYouContact"));
        history.push("/");
      } else {
        toast.error(t("errorOccurred"));
      }
    } catch (error) {
      console.error("Error fetching data:", error.response);
      toast.error(t("errorOccurred"));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>;
      </div>
    );

  return (
    <div>
      {/* <div className="googlemap"> */}
      {/* <iframe
          src={mapUrl}
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}
      {/* </div> */}
      <div ref={mapRef} style={{ height: "300px", width: "100%" }}>
        {initializeMap()}
      </div>

      <div className="auto cm_2">
        <div className="content">
          <h1>{t("contact")}</h1>
          <p>{subTitle}</p>
          <div className="contactinfo">
            <p>
              <strong>{company}</strong>{" "}
            </p>
            <p>{address1}</p>
            <p>{address2}</p>
            <p>
              {t("telephoneText")}: <a href={`tel:${tele}`}>{tele}</a>
            </p>
            <p>
              {t("mobile")}: <a href={`tel:${ph1}`}>{ph1}</a>
            </p>
            <p>
              {t("mobile")}: <a href={`tel:${ph2}`}>{ph2}</a>{" "}
            </p>
            <p>
              <a href={`mailto:${mail}`}>{mail}</a>
            </p>
          </div>
        </div>
      </div>
      <div className="cm_form contfrom_form">
        <div className="auto">
          <div className="heading">{t("contactForm")}</div>
          <form
            name="form_free"
            className="c_form contactfrom"
            onSubmit={handleSubmit}
          >
            <div className="c_form_string c_field">
              <div
                className={`material-textfield ${
                  name === "" ? "emptyvalue" : "activevalue"
                }`}
              >
                <input
                  placeholder={t("namePlaceholder")}
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label>{t("nameLabel")}</label>
              </div>
              <div className="inprow_opr2">
                <div
                  className={`material-textfield ${
                    email === "" ? "emptyvalue" : "activevalue"
                  }`}
                >
                  <input
                    placeholder={t("emailPlaceholder")}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>{t("emailLabel")}</label>
                </div>
                <div
                  className={`material-textfield ${
                    phone === "" ? "emptyvalue" : "activevalue"
                  }`}
                >
                  <input
                    placeholder={t("phonePlaceholder")}
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  <label>{t("phoneLabel")}</label>
                </div>
              </div>
              <div className="inprow_opr1">
                <div
                  className={`material-textfield2 ${
                    selectOption === "" ? "emptyvalue" : "activevalue"
                  }`}
                >
                  <Box>
                    <FormControl fullWidth>
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{ width: "500px" }}
                      >
                        {t("requestTypeLabel")}
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        value={selectOption}
                        label={t("requestTypeLabel")}
                        renderValue={(selected) => {
                          if (selected === "") {
                            return (
                              <em
                                style={{ fontSize: "0.9rem", color: "#676767" }}
                              >
                                {t("selectInquiry")}
                              </em>
                            );
                          }
                          return selected;
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => setSelectOption(e.target.value)}
                        open={menuOpen} // Control the menu's open state
                        onClose={() => setMenuOpen(false)} // Close menu on select close
                        onOpen={() => setMenuOpen(true)} // Open menu on select open
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          // getContentAnchorEl: null, // Ensures proper menu positioning
                          disableScrollLock: true, // Prevents scroll lock on mobile
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              // width: "100%", // Ensures full-width menu
                            },
                          },
                        }}
                        ref={selectRef}
                      >
                        <MenuItem value={t("selectOptions.sellProperty")}>
                          {t("selectOptions.sellProperty")}
                        </MenuItem>
                        <MenuItem value={t("selectOptions.buyProperty")}>
                          {t("selectOptions.buyProperty")}
                        </MenuItem>
                        <MenuItem value={t("selectOptions.generalInquiry")}>
                          {t("selectOptions.generalInquiry")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
              <div className="inprow_opr1">
                <div
                  className={`material-textfield modfinfo2 ${
                    subject === "" ? "emptyvalue" : "activevalue"
                  }`}
                >
                  <textarea
                    id="textarea_subject"
                    rows="6"
                    cols="40"
                    placeholder={t("subjectPlaceholder")}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  ></textarea>
                  <label>{t("subjectLabel")}</label>
                </div>
              </div>

              <div className="c_form_checkbox c_field">
                <label className="" data-id="agreed">
                  <input
                    type="checkbox"
                    id="checkbox_agreed"
                    data-id="agreed"
                    checked={agreed}
                    onChange={() => setAgreed(!agreed)}
                  />
                  {t("privacyPolicyText")}{" "}
                  <a
                    href="/datenschutz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("privacyPolicyLinkText")}
                  </a>
                  *
                </label>
              </div>
            </div>

            <div className="row two narrow end actions">
              <button type="submit" className="button index_0 ">
                {t("submitButton")}
              </button>
            </div>
          </form>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="error">
            {error.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ContactUs;
