import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import VideoPlayer from "../../components/VideoPlayer";

const PresentationOfProperties = () => {
  const { i18n, t } = useTranslation();
  const [videoData, setVideoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getVideoIdFromLink = (link) => {
    const url = new URL(link);
    return url.pathname === "/watch"
      ? url.searchParams.get("v")
      : url.pathname.slice(1);
  };
  const token =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  useEffect(() => {
    let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/presentation-of-properties?locale=${lang}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        const filteredData =
          response.data.data?.sort(
            (a, b) => a.attributes.Order - b.attributes.Order
          ) || [];
        setVideoData(filteredData);
        setLoading(false);
        console.log(filteredData);
      })
      .catch((error) => {
        // console.log(error.message);
        setLoading(false);
      });
  }, [i18n.language]);
  if (loading)
    return (
      <div
        style={{
          display: "flex",
          minHeight: 600,
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <p>{t("loadingText")}</p>;
      </div>
    );
  return (
    <>
      <div className="innercontent_wrap">
        <div className="innercontent">
          <div className="e_data_ids">
            <div className="auto cm_17">
              <div className="divide heading txt_1">
                {t("presentation-of-properties")}
              </div>

              <div
                className="auto row spaced cols_2"
                style={{ direction: "flex", flexWrap: "wrap" }}
              >
                {videoData.length == 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "300px",
                      width: "100vw",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        gap: "10px", // optional spacing between items
                      }}
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/17569/17569031.png"
                        alt="No Properties"
                        style={{ height: 220, width: 320 }}
                      />
                      <span
                        style={{
                          marginTop: 15,
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#013",
                        }}
                      >
                        {t("noVideo")}
                      </span>
                    </div>
                  </div>
                ) : (
                  videoData.map((video, index) => {
                    const videoAttributes = video.attributes;
                    const youtubeData = JSON.parse(videoAttributes.Youtube);
                    return (
                      <div
                        key={index}
                        className="videoinfo"
                        style={{ marginTop: 5 }}
                      >
                        <div className="ratio c_media_youtube">
                          <VideoPlayer
                            key={index}
                            videoId={getVideoIdFromLink(youtubeData.url)}
                          />
                        </div>
                        <div className="content" style={{ marginTop: 5 }}>
                          <div className="heading txt_2">
                            {video.attributes.Title}
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresentationOfProperties;
