import React, {useState, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import property_pic1 from "../../assets/img/property_pic1.png";
import property_pic2 from "../../assets/img/property_pic2.png";
import property_pic3 from "../../assets/img/property_pic3.png";
import property_pic4 from "../../assets/img/property_pic4.png";

import { useFavorites } from "../Context/FavoritesContext";
import CheckIcon from "@mui/icons-material/Check";

import Slider from "react-slick";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useTranslation } from "react-i18next";
import useStore from "../../Store/useStore";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};


const OfferOfTheWeek = ({ engData, spaData, gerData, loading }) => {
  // // console.log(engData)
  // // console.log(spaData)
  // // console.log(gerData)
  const { t, i18n } = useTranslation();
const formatPrice = (price) => {
  const locale = i18n.language.startsWith('en') ?  'en-US' : 'de-DE' ;
    return new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(price);
};
  const selectLanguage = useStore((state) => state.selectLanguage);
  
  const { favorites, toggleFavorite, espFavorites, gerFavorites } = useFavorites();
  const history = useHistory();
  const [data, setData] = useState([])
  const handleCardClick = (item, index) => {
    // console.log("selected", item);
    const sanitizeString = (str) => {
      return str
                .replace(/\s+/g, '-') // Replace spaces with hyphens
                .replace(/[^\w-]+/g, ''); // Remove all non-word characters except hyphens
    };
  
    const sanitizedOrt = sanitizeString(item.elements.ort);
    const sanitizedObjektart = sanitizeString(item.elements.objektart);
    const refId = sanitizeString(item.elements.objektnr_extern)
    const dynamicUrl = `/propertydetails/${sanitizedOrt}/${item.id}-${sanitizedObjektart}/${refId}`;

    setTimeout(() => {
      history.push({
        pathname: dynamicUrl,
        state: { property: engData[0], gerProperty: gerData[0], espProperty: spaData[0] },
      });
    }, 1000);
  };
  useEffect(() => {
    // // console.log(selectLanguage);
    selectLanguage === "en" ? setData(engData) : selectLanguage === "es" ? setData(spaData) : selectLanguage === "de" ? setData(gerData) : setData(engData)
  }, [selectLanguage, engData, spaData, gerData, data])

  const isFavorite = 
  favorites.some((fav) => fav.elements.objektnr_extern === engData[0]?.elements.objektnr_extern) ||
  espFavorites.some((fav) => fav.elements.objektnr_extern === spaData[0]?.elements.objektnr_extern) ||
  gerFavorites.some((fav) => fav.elements.objektnr_extern === gerData[0]?.elements.objektnr_extern);

  const renderSkeleton = () => (
    <div className="c_4">
      <div className="slides_area">
        <div className="c_slider">
          <div className="slides_content">
            <div className="sliderimage">
              <Skeleton height={400} />
            </div>
          </div>
        </div>
      </div>
      <div className="box">
        <div className="tags">
          {/* <div className="c-5">
          Neu gelistet
          </div> */}
        </div>
        <div className="row keep spaced">
          <div className="location">
            <Skeleton width={80} />
          </div>
          <div>
            {/* Ref:  */}
            <Skeleton width={60} />
          </div>
        </div>
        <div className="heading txt_1">
          <Skeleton width={200} />
        </div>
        <div className="row keep spaced center_v">
          {/* <div>Verkaufspreis</div> */}
          <div className="price">
            <Skeleton width={80} />
          </div>
        </div>
        <div className="row keep spaced details">
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            {/* <div>Schlafzimmer</div> */}
            <div>
              <b>
                <Skeleton width={30} />
              </b>
            </div>
          </div>
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            {/* <div>Bäder</div> */}
            <div>
              <b>
                <Skeleton width={30} />
              </b>
            </div>
          </div>
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            {/* <div>Wohnfläche</div> */}
            <div>
              <b>
                <Skeleton width={30} />
              </b>
            </div>
          </div>
          <div className="center_v">
            <Skeleton width={50} height={50} circle={true} />
            {/* <div>Grundstück</div> */}
            <div>
              <b>
                <Skeleton width={30} />
              </b>
            </div>
          </div>
        </div>
        <div className="row actions">
          <Skeleton width={100} height={30} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <section className="weekoffer">
        <div className="auto cm_5">
          <div className="heading txt_1 divide">{t('offerOfTheWeek')}</div>       
            <React.Fragment>{renderSkeleton()}</React.Fragment>
        </div>
      </section>
    );
  }

  return (
    <section className="weekoffer">
      <div className="auto cm_5">
        <div className="heading txt_1 divide">{t('offerOfTheWeek')}</div>

        {data?.length > 0 ?
        
        
        data?.map((item, index) => (
          <div className="c_4" key={index}>
            <div className="slides_area" >
              <div className="c_slider">
                <div className="slides_content">
                  <div className="sliderimage">             
                    <Slider {...settings}>             
                      {item.elements.urls.map((image, i) => (
                        <a className="link" key={i}>
                          <img
                            className=""
                            style={{maxHeight: '450px'}}
                            alt="not set"
                            title=""
                            src={`${image.url}`}                          
                          />
                        </a>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="tags">
                <div className="c-5">{t('newlyListed')}</div>
              </div>
              <div className="row keep spaced">
                <div className="location">
                  {item.elements.ort === "" ? "nA" : item.elements.ort}
                </div>
                <div>
                  Ref:{" "}
                  {item.elements.objektnr_extern === ""
                    ? "nA"
                    : item.elements.objektnr_extern}
                </div>
              </div>
              <div className="heading txt_1" style={{cursor:'pointer'}} onClick={() => handleCardClick(item, index)}>
                {item.elements.objekttitel === ""
                  ? "Not Set"
                  : item.elements.objekttitel}
              </div>
              <div className="row keep spaced center_v">
                <div>{t('salePrice')}</div>
                <div className="price">
                  € {formatPrice(item.elements.kaufpreis)}
                </div>
              </div>
              <div className="row keep spaced details">
                <div className="center_v">
                  <img
                    className="sprites"
                    alt="bedroom icon"
                    src={property_pic1}
                  />
                  <div>{t('bedrooms')}</div>
                  <div>
                    <b>
                      {item.elements.anzahl_schlafzimmer === ""
                        ? "not set"
                        : parseInt(item.elements.anzahl_schlafzimmer)}{" "}
                    </b>
                  </div>
                </div>
                <div className="center_v">
                  <img
                    className="sprites"
                    alt="bathroom icon"
                    src={property_pic2}
                  />
                  <div>{t('bathrooms')}</div>
                  <div>
                    <b>
                      {item.elements.anzahl_badezimmer === ""
                        ? "not set"
                        : parseInt(item.elements.anzahl_badezimmer)}
                    </b>
                  </div>
                </div>
                <div className="center_v">
                  <img
                    className="sprites"
                    alt="inner space icon"
                    src={property_pic3}
                  />
                  <div>{t('livingArea')}</div>
                  <div>
                    <b>
                      {item.elements.wohnflaeche === ""
                        ? "not set"
                        : `${parseInt(item.elements.wohnflaeche)}㎡`}
                    </b>
                  </div>
                </div>
                <div className="center_v">
                  <img
                    className="sprites"
                    alt="property space icon"
                    src={property_pic4}
                  />
                  <div>{t('propertySpace')}</div>
                  <div>
                    <b>
                      {item.elements.grundstuecksflaeche === ""
                        ? "not set"
                        : `${parseInt(item.elements.grundstuecksflaeche)}㎡`}
                    </b>
                  </div>
                </div>
              </div>
              <div className="row actions">
                {/* <div className="c_1_1 light c_selected_marker"> */}
                <a
                  href="javascript:void(0)"
                  className={`c_1_1 light c_selected_marker ${
                    isFavorite ? "favorite" : ""
                  }`}
                  onClick={() => toggleFavorite(engData[0], spaData[0], gerData[0])}
                >
                  {isFavorite ? (
                    <>
                      <CheckIcon style={{ color: "#666973" }} /> {t("saved")}
                    </>
                  ) : (
                    t("remember")
                  )}
                </a>

                <div
                  className="c_1_1"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleCardClick(item, index)}
                >
                  {t("showEstate")}
                </div>
              </div>
            </div>
          </div>
        )) : <div style={{heigth:300, display:'flex', alignItems:'center'}}>
          {t('offerOfWeekText')}..
          </div>}
      </div>
    </section>
  );
};

export default OfferOfTheWeek;
