import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
// import AddBook from "../fetaures/books/AddBook";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";

import Error from "../pages/Error";
import Home from "../pages/Home/Home";
import PropertySearch from "../pages/PropertySearch/PropertySearch";
import PropertyDetails from "../pages/PropertyDetails/PropertyDetails";

import RealEstateSales from "../pages/RealEstateSales/RealEstateSales";
import OurTeam from "../pages/OurTeam/OurTeam";
import Counselor from "../pages/Counselor/Counselor";
import CounselorMoreDetails from "../pages/Counselor/CounselorMoreDetails";
import ServiceGarantie from "../pages/ServiceGarantie/ServiceGarantie";
import Partner from "../pages/partner/partner";
import LifestyleGuide from "../pages/LifestyleGuide/LifestyleGuide";
import VideoTouren from "../pages/VideoTouren/VideoTouren";
import ContactUs from "../pages/ContactUs/ContactUs";

import Impressum from "../pages/Impressum/Impressum";
import DataProtection from "../pages/DataProtection/DataProtection";
import Favorites from "../pages/Favorites/Favorites";

import BuldingMallorca from "../pages/BuildingMallorca/BuldingMallorca";
import HorizontalDE from "../pages/Lionsgate/HorizontalIFrameDE";
import HorizontalES from "../pages/Lionsgate/HorizontalIFrameES";
import HorizontalIN from "../pages/Lionsgate/HorizontalIFrameIN";

import BePartOfTeam from "../pages/BePartOfTeam/BePartOfTeam";
import ScrollToTop from "./ScrollToTop";
import RedirectToSearch from "../pages/Intermediate/RedirectToSearch";
import InfoGuide from "../pages/InfoGuide/InfoGuide";
import PresentationOfProperties from "../pages/PresentationOfProperties/PresentationOfProperties";
const Index = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <main>
        <Switch>
          <Route path={'/redirect-to-search'} component={RedirectToSearch}/>
          <Route exact path="/" component={Home} />
          <Route path="/propertysearch" component={PropertySearch} />
          <Route
            path="/:lang/propertydetails/:ort/:id-:objektart/:refId"
            component={PropertyDetails}
          />
          <Route
            path="/propertydetails/:ort/:id-:objektart/:refId"
            component={PropertyDetails}
          />
          <Route path="/verkauf" component={RealEstateSales} />
          <Route path="/unser-team" component={OurTeam} />
          {/* <Route path="/ratgeber" component={Counselor} />
          <Route
            path="/ratgeberdetails/:slug"
            component={CounselorMoreDetails}
          /> */}
          <Route path="/service-garantie" component={ServiceGarantie} />
          <Route path="/partner" component={Partner} />
          <Route path="/lifestyle_guide" component={LifestyleGuide} />
          <Route path="/video-touren" component={VideoTouren} />
          <Route path="/buildingBauen-auf-Mallorca" component={BuldingMallorca} />
          <Route
            path="/kontakt-immobilienmakler-mallorca"
            component={ContactUs}
          />
          <Route path="/join-team" component={BePartOfTeam} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={DataProtection} />
          <Route path="/favorites" component={Favorites} />
          <Route path="/horizontal" component={HorizontalIN} />
          <Route path="/horizontal-de" component={HorizontalDE} />
          <Route path="/horizontal-en" component={HorizontalES} />
          
          <Route path="/presentation-of-properties" component={PresentationOfProperties} />
          <Route path="/info-guide" component={InfoGuide} />
          {/* <Route component={Error} /> */}
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default Index;
