import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { base_url } from "./../../api/const";
import ReactMarkdown from "react-markdown";
import Brands from '../../components/Brands/Brands';
import { Helmet } from 'react-helmet';
const ServiceGarantie = () => {
	const { i18n, t } = useTranslation();
	const [content, setContent] = useState("");
	const [loading, setLoading] = useState(true);
	const [img, setImg] = useState('')
	const link =
	i18n.language.startsWith('de')
	  ? 'https://lp.sae-mallorca.com/'
	  : 'https://lp.sae-mallorca.com/en/home/';
  
	const token =
	  "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
	useEffect(() => {
	  let lang = i18n?.language.startsWith("en")
      ? "en"
      : i18n?.language.startsWith("es")
      ? "es"
      : i18n?.language.startsWith("de")
      ? "de"
      : "de";
	  // console.log(lang);
	  axios
		.get(`${base_url}/service-gurantee?locale=${lang}&populate=*`, {
		
		  method: "GET",
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		})
		.then((response) => {
		  // console.log(response.data.data.attributes);
	  
		  setContent(response.data.data.attributes.content_field);
		  const baseUrl = "https://www.sae-mallorca.com/propertywebsite" 
		  let image = response.data.data.attributes.header_image.data.attributes.formats.large.url
		  setImg(baseUrl + image)
		  // console.log(baseUrl + image)
		  setLoading(false);
		})
		.catch((error) => {
		  // console.log(error.message);
		  setLoading(false);
		});
	}, [i18n.language]);
	if (loading)
		return (
		  <div
			style={{
			  display: "flex",
			  minHeight: 600,
			  justifyContent: "center",
			  alignItems: "center",
			  flex: 1,
			}}
		  >
			<p>{t("loadingText")}</p>;
		  </div>
		);
  return (
	
    <>
	<Helmet>
        <meta itemprop="inLanguage" content="de" />
        <meta
          name="description"
          itemprop="description"
          content={content}
        />

        <link
          rel="canonical"
          href={`https://www.sae-mallorca.com/service-garantie`}
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/service-garantie`}
          hreflang="de"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/service-garantie`}
          hreflang="en"
        />
        <link
          rel="alternate"
          href={`https://www.sae-mallorca.com/service-garantie`}
          hreflang="es"
        />
        <link rel="icon" href={`${img}`} />
        <meta property="og:locale" content="de_DE" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={t('serviceGuarantee')} />
        <meta property="og:image" content={`${img}`} />
        <meta property="og:description" content={content} />
        <meta
          property="og:url"
          content={`https://www.sae-mallorca.com/service-garantie`}
        />
        <meta
          property="og:site_name"
          content="Immobilien Mallorca kaufen |S.A.E Real Estate Mallorca"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content={content} />
        <meta name="twitter:title" content={t('serviceGuarantee')} />
      </Helmet>
		<div className="innercontent_wrap">
			<Brands headerImg img={img}/>
			<div className="innercontent">
				<div className="auto cm_2">
				<ReactMarkdown>{content}</ReactMarkdown>
				</div>
			</div>
		</div>
    </>
  );
};

export default ServiceGarantie;
