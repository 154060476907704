import React,{ useState, useEffect } from "react";
import axios from "axios";
import facebookicon from "../assets/img/facebook.png";
import { Link } from "react-router-dom";
import footerlogo from "../assets/img/logo.png";
import ins from "../assets/img/ins.png";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { base_url } from "../api/const";
import useStore from "../Store/useStore";


const Footer = () => {
  const setFilters = useStore((state) => state.setFilters);
  const page = useStore((state) => state.page);
  const setPage = useStore((state) =>state.setPage);
  const clearFilters = useStore((state) => state.clearFilters);
  const [addressline1, setAddressLine1] = useState("");
  const [addressline2, setAddressLine2] = useState("");
  const [fbUrl, setFBUrl] = useState("");
  const [instaUrl,setInstaUrl] = useState("");
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const currentLanguage = i18n.language;
  const location = [
    //Genova
    { id: "200", name: "Genova" },

    // Puig de Ros
    { id: "4", name: "Puig De Ros" },

    //  Palma de Mallorca
    { id: "11", name: "Palma de Mallorca" },
    { id: "1", name: "Palma" },
    { id: "126", name: "Platja de Palma" },

    // Llucmajor
    { id: "25", name: "Llucmajor / Son Verí Nou" },
    { id: "5", name: "Llucmajor" },

    // S'Arenal
    { id: "14", name: "Llucmajor / s'Arenal" },
    { id: "58", name: "S'Arenal" },

    // Bendinat
    { id: "82", name: "Bendinat" },

    //Costa de la Calma
    { id: "77", name: "Calvià / Costa de la Calma" },

    // Costa d'en Blanes
    { id: "30", name: "Calvià / Costa d'en Blanes" },

    // Calvia
    { id: "8", name: "Calvià / Santa Ponça" },
    { id: "24", name: "Calvià / el Toro" },
    { id: "30", name: "Calvià / Costa d'en Blanes" },
    { id: "31", name: "Calvià" },
    { id: "41", name: "Calvià / Palmanova" },
    { id: "77", name: "Calvià / Costa de la Calma" },

    // Santa Ponsa
    { id: "8", name: "Calvià / Santa Ponça" },
    { id: "98", name: "santa ponsa" },
  ];

  const locationsMap = {
    Genova: [{ id: "200", name: "Genova" }],
    "Puig De Ros": [{ id: "4", name: "Puig De Ros" }],
    Palma: [
      { id: "11", name: "Palma de Mallorca" },
      { id: "1", name: "Palma" },
      { id: "126", name: "Platja de Palma" },
    ],
    Llucmajor: [
      { id: "25", name: "Llucmajor / Son Verí Nou" },
      { id: "5", name: "Llucmajor" },
    ],
    "S'Arenal": [
      { id: "14", name: "Llucmajor / s'Arenal" },
      { id: "58", name: "S'Arenal" },
    ],
    Bendinat: [{ id: "82", name: "Bendinat" }],
    "Costa de la Calma": [{ id: "77", name: "Calvià / Costa de la Calma" }],
    "Costa d'en Blanes": [{ id: "30", name: "Calvià / Costa d'en Blanes" }],
    Calvia: [
      { id: "8", name: "Calvià / Santa Ponça" },
      { id: "24", name: "Calvià / el Toro" },
      { id: "30", name: "Calvià / Costa d'en Blanes" },
      { id: "31", name: "Calvià" },
      { id: "41", name: "Calvià / Palmanova" },
      { id: "77", name: "Calvià / Costa de la Calma" },
    ],
    "Santa Ponsa": [
      { id: "8", name: "Calvià / Santa Ponça" },
      { id: "98", name: "santa ponsa" },
    ],
  };

  const handleLocationClick = (locationName) => {
    // console.log(locationName);
    const filteredLocations = locationsMap[locationName];
    // console.log(filteredLocations); // For debugging purposes
    setTimeout(() => {
      setPage(1)
    setFilters(prevFilters => ({
      ...prevFilters,
      selectedLocations: filteredLocations
    }));
  }, 500);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      history.push({
        pathname: "/propertysearch",
        state: { 
          refNumber: "",
          maximumPrice: "",
          minimumPrice: "",
          numberOfBed: "",
          filteredLocations: locationsMap[locationName] },
          typeOfProperty: [],
      });
    }, 1000);
  };

  const handlePropertyType = (val) => {
    // console.log([val]); 
    setTimeout(() => {
      setPage(1)
    setFilters(prevFilters => ({
      ...prevFilters,
      propertyType: [val],
    }));
  }, 1000)
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      history.push({
        pathname: "/propertysearch",
        state: {  refNumber: "",
          typeOfProperty: [val] ,
          maximumPrice: "",
          minimumPrice: "",
          numberOfBed: "",
          filteredLocations: [],
        },
      });
    }, 1000);
  };
  const tokens =
    "8f1a4299209e0b780df3d6aae497cb58040e75834b5724d412c0bd966c0fb0cd6af2fcf92e739708a9b63260512f5c3cd13c43ad4f0e716869511712bf152b62575d2c4872f46f935f6168b7b12225e67a35f2ee48265a09090803cf0b804ccb731702bb767c55dcd55427c8e897454014b4fe936767203145f6e4210384bda5";
  const homeContent = async () => {
    let lang = i18n?.language.startsWith("en")
    ? "en"
    : i18n?.language.startsWith("es")
    ? "es"
    : i18n?.language.startsWith("de")
    ? "de"
    : "de";
    // console.log(lang);
    axios
      .get(`${base_url}/home-page-setting`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokens}`,
        },
      })
      .then((response) => {
        // console.log(response.data.data.attributes);
        const { attributes } = response.data.data;
        setAddressLine1(attributes.addressline1);
        setAddressLine2(attributes.addressline2);
        setFBUrl(attributes.facebook_url)
        setInstaUrl(attributes.instagram_url);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  useEffect(() => {
    homeContent()
  }, [])
  
  return (
    <>
      <footer className="c_footer">
        <div className="alpha">
          <div className="row spaced grow">
            <div>
              <div className="txt_3">
                <p>{t("property")}</p>
              </div>
              <ul>
                <li>
                  {" "}
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Genova")}
                  >
                    {t("property")} Genova{" "}
                  </p>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Puig De Ros")}
                  >
                    {t("property")} Puig de Ros
                  </div>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Palma")}
                  >
                    {t("property")} Palma de Mallorca
                  </div>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Llucmajor")}
                  >
                    {t("property")} Llucmajor
                  </div>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("S'Arenal")}
                  >
                    {t("property")} S'Arenal
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <div className="txt_3">
                <p>{t("property")}</p>
              </div>
              <ul>
                <li>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Bendinat")}
                  >
                    {t("property")} Bendinat
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Costa de la Calma")}
                  >
                    {t("property")} Costa de la Calma
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Costa d'en Blanes")}
                  >
                    {t("property")} Costa d'en Blanes
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Calvia")}
                  >
                    {t("property")} Calvia
                  </p>
                </li>
                <li>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => handleLocationClick("Santa Ponsa")}
                  >
                    {t("property")} Santa Ponsa
                  </p>
                </li>
              </ul>
            </div>
            <div>
              <div className="txt_3">{t("type")}</div>
              <ul>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePropertyType("haus")}
                    className=""
                  >
                    {t("houseVillaFooter")}
                  </div>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePropertyType("gastgewerbe")}
                    className=""
                  >
                    {t("commercialFooter")}
                  </div>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePropertyType("neubau")}
                    className=""
                  >
                    {t("newBuildFooter")}
                  </div>
                </li>
                <li>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => handlePropertyType("wohnung")}
                    className=""
                  >
                    {t("apartmentFooter")}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* <div className="txt_3"> Lionsgate</div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: 20,
              paddingBottom: 20,
            }}
          >
            {currentLanguage.startsWith('en') ?
            <a href="/horizontal-in" target="_blank" rel="noopener noreferrer">
              Mortgage Calculator
            </a>
            :currentLanguage.startsWith('es') ?
            <a href="/horizontal-en" target="_blank" rel="noopener noreferrer">
            Calculadora hipotecaria
          </a>
          :
            <a href="/horizontal-de" target="_blank" rel="noopener noreferrer">
              Hypothekenrechner
            </a>
}
           
          </div> */}
        </div>
        <nav className="nav-social">
          <ol className="row keep center_vh">
            <li>
              <p>
                <a href={fbUrl} target="_blank" rel="noopener noreferrer">
                  <img
                    src={facebookicon}
                    alt="Facebook"
                    title="Facebook Icon"
                  />
                </a>
              </p>
              <span></span>
            </li>
            <li>
              <p>
                <a href={instaUrl} target="_blank" rel="noopener noreferrer">
                  <img src={ins} alt="Instagram" title="Instagram Icon" />
                </a>
              </p>
              <span></span>
            </li>
          </ol>
        </nav>
        {/* <div style={{display:"flex", width:'100%', justifyContent:'space-evenly', marginTop:20}}>

        <a href="/horizontal-in" target="_blank" rel="noopener noreferrer">
        Horizontal IN
      </a>
      <a href="/horizontal-de" target="_blank" rel="noopener noreferrer">
        Horizontal DE
      </a>
      <a href="/horizontal-en" target="_blank" rel="noopener noreferrer">
        Horizontal EN
      </a>
        </div> */}

        <div className="beta">
          <a href="index.html" className="logo_container">
            <img className="logo" alt="logo" src={footerlogo} />
          </a>
          <div className="row center">
            <p>
              <span itemType="http://schema.org/PostalAddress">
                <span>{addressline1}</span>

                {/* <span itemprop="postalCode">07012 </span> */}
                <span itemProp="addressLocality">{addressline2}</span>
              </span>
              <span itemProp="image" className="hide">
                https://www.sae-mallorca.com/assets/img/logo.png
              </span>
              <span itemProp="url" className="hide">
                https://www.sae-mallorca.com
              </span>
            </p>
          </div>
          <div className="hide"></div>
          <ul className="row auto spaced around beta">
            <li>
              <Link to="/" onClick={() => {clearFilters(); setPage(1)}}>{t("mallorcaProperties")}</Link>
            </li>
            <li className="sep"> | </li>
            <li>
              <Link to="/impressum" onClick={() => {clearFilters(); setPage(1)}}>{t("imprint")}</Link>
            </li>
            <li className="sep"> | </li>
            <li>
              <Link to="/datenschutz" onClick={() => {clearFilters(); setPage(1)}}>{t("dataProtection")}</Link>
            </li>
            <li className="sep"> | </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
