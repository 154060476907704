// import React from "react";
import React, { useEffect, useState, useRef } from "react";

import logo from "../assets/img/logo.png";
import srarchicon from "../assets/img/srarchicon.png";
import dropdownmenu from "../assets/img/down-arrow.png";
import { Link, useHistory, useParams } from "react-router-dom";
// import { } from 'react-router-dom';

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useFavorites } from "../components/Context/FavoritesContext";
import useStore from "../Store/useStore";
import { useTranslation } from "react-i18next";
const Header = () => {
  const setFilters = useStore((state) => state.setFilters);
  const page = useStore((state) => state.page);
  const setPage = useStore((state) => state.setPage);
  const { favorites, gerFavorites, espFavorites } = useFavorites();
  const [languagecont, setLanguagecont] = React.useState("10");
  const [refNumber, setRefNumber] = useState("");
  const [showRefNum, setShowRefNum] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [propertyShow, setPropertyShow] = useState(false);
  // const data = useStore((state) => state.data);
  // const setData = useStore((state) => state.setData);
  const estates = useStore((state) => state.estates);
  const setEstates = useStore((state) => state.setEstates);
  const loading = useStore((state) => state.loading);
  const setLoading = useStore((state) => state.setLoading);
  // const allData = useStore((state) => state.allData);
  const setAllData = useStore((state) => state.setAllData);
  const selectLanguage = useStore((state) => state.selectLanguage);
  const setSelectLanguage = useStore((state) => state.setSelectLanguage);
  const { t, i18n } = useTranslation();
  const clearFilters = useStore((state) => state.clearFilters);

  const [menuOpen, setMenuOpen] = useState(false);
  const selectRef = useRef(null); // Ref for the Select component

  // Scroll event handler
  const handleScroll = () => {
    if (menuOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (!isVisible) {
        setMenuOpen(false); // Close the menu if it goes out of view
      }
    }
  };

  useEffect(() => {
    if (menuOpen) {
      // Add scroll event listener when the menu is open
      window.addEventListener("scroll", handleScroll);
    } else {
      // Remove scroll event listener when the menu is closed
      window.removeEventListener("scroll", handleScroll);
    }

    // Cleanup: Remove event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuOpen]);

  const handleChange = async (event) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
    setSelectLanguage(language);
    setLanguagecont(language);

    if (window.location.pathname.includes("propertydetails")) {
      let newPath = window.location.pathname.slice(1).split("/");
      newPath[0] = language;
      navigate.replace("/" + newPath.join("/"));
    }
  };

  const dataToSend = {
    refNumber: "",
    maximumPrice: "",
    minimumPrice: "",
    filteredLocations: [],
    typeOfProperty: [],
    numberOfBed: "",
  };

  const toogleMenu = () => {
    setShowMenu(!showMenu);
  };

  // const handleChange = (event) => {
  //   setLanguagecont(event.target.value);
  // };
  const navigate = useHistory();

  const searchReference = () => {
    // console.log("first");
    setShowRefNum("");
    setTimeout(() => {
      setRefNumber("");
    }, 1000);

    setTimeout(() => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        refNo: refNumber,
      }));
      setPage(1);
      navigate.push({
        pathname: "/propertysearch",
        state: {
          refNumber: refNumber,
          maximumPrice: "",
          minimumPrice: "",
          filteredLocations: [],
          typeOfProperty: [],
          numberOfBed: "",
        },
      });
    }, 500);
  };

  const handlePropertySearchNavigation = (propertyType) => {
    setTimeout(() => {
      setPage(1);
      navigate.push({
        pathname: "/propertysearch",
        state: {
          typeOfProperty: [propertyType],
          maximumPrice: "",
          minimumPrice: "",
          refNumber: "",
          filteredLocations: [],
          numberOfBed: "",
        },
      });
    }, 1000);
    toogleMenu();
  };
  const handleFavorites = (val) => {
    // console.log(val);
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
      navigate.push({
        pathname: "/favorites",
        state: {
          data: favorites,
          gerData: gerFavorites,
          espData: espFavorites,
        },
      });
    }, 1000);
  };

  const handleInputChange = (event) => {
    setRefNumber(event.target.value);
    setShowRefNum(event.target.value);
    // console.log(event.target.value);
  };

  const handlePropertySearchScreen = () => {
    // setData(allData);
    toogleMenu();
    // setPropertyShow(!propertyShow);
  };

  const removeFilters = () => {
    clearFilters();
    setPage(1);
  };

  return (
    <section className="header-wrapper">
      <div className="container for-small-wrapper">
        <div className="header_top">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              searchReference();
            }}
          >
            <div className="ref_search_opt">
              <div className="material-textfield">
                <input
                  placeholder={t("refNumber")}
                  type="text"
                  value={showRefNum}
                  onChange={handleInputChange}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter" || e.keyCode === 13) {
                  //     e.preventDefault(); // Prevents the default form submission behavior
                  //     searchReference();
                  //   }
                  // }}
                />
                <label>{t("refNumber")}</label>
              </div>
              <button
                type="submit"
                className="btn btn-default"
                onClick={searchReference}
                style={{ cursor: "pointer" }}
              >
                <img src={srarchicon} alt={t("search")} />
              </button>
            </div>
          </form>
          <div className="select_language-cont">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("language")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectLanguage}
                  label={t("language")}
                  onChange={handleChange}
                  open={menuOpen} // Control the menu's open state
                  onClose={() => setMenuOpen(false)} // Close menu on select close
                  onOpen={() => setMenuOpen(true)} // Open menu on select open
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null, // Ensures proper menu positioning
                    disableScrollLock: true, // Prevents scroll lock on mobile
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        // width: "100%", // Ensures full-width menu
                      },
                    },
                  }}
                  ref={selectRef}
                >
                  <MenuItem value="de">{t("de")}</MenuItem>
                  <MenuItem value="en">{t("en")}</MenuItem>
                  <MenuItem value="es">{t("es")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          {favorites.length > 0 && (
            <p
              className="favorites_opt"
              style={{ marginTop: 5, cursor: "pointer" }}
              onClick={() => handleFavorites(favorites)}
            >
              {t("favorites")}:{" "}
              <span className="count">{favorites.length}</span>
            </p>
          )}
        </div>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/" onClick={removeFilters}>
              <img
                src={logo}
                alt="brand-logo"
                className="img-fluid brand-logo"
              />
            </Link>
            <div
              className={`collapse navbar-collapse ${
                showMenu ? "show" : "hidden"
              }`}
              id="navbarSupportedContent"
            >
              <div className="navbarcontent_des">
                <button
                  type="button"
                  className="navbar-toggler cross"
                  onClick={toogleMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>

                <ul className="navbar-nav ml-auto topMenu">
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link active"
                          : "headerSize nav-link active"
                      }
                      // "nav-link active"
                      aria-current="page"
                      to={{
                        pathname: "/propertysearch",
                        state: dataToSend,
                      }}
                      onClick={handlePropertySearchScreen}
                    >
                      {t("propertySearch")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      to={{
                        pathname: "/propertysearch",
                        state: dataToSend,
                      }}
                      onClick={handlePropertySearchScreen}
                    >
                      {t("properties")}

                      <img
                        src={dropdownmenu}
                        alt="Open Menu"
                        className="vis"
                        onClick={() => setPropertyShow(!propertyShow)}
                      />
                    </Link>
                    {/* {propertyShow ? */}
                    <ul className="nav drop-menu">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          // href=""
                          onClick={() =>
                            handlePropertySearchNavigation("gastgewerbe")
                          }
                        >
                          <span className="nav-link-drop">
                            {t("commercial")}
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          // href=""
                          onClick={() =>
                            handlePropertySearchNavigation("grundstueck")
                          }
                        >
                          <span className="nav-link-drop">{t("land")}</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={() => handlePropertySearchNavigation("haus")}
                        >
                          <span className="nav-link-drop">
                            {t("houseVilla")}
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={() =>
                            handlePropertySearchNavigation("neubau")
                          }
                        >
                          <span className="nav-link-drop">{t("newBuild")}</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={() =>
                            handlePropertySearchNavigation("wohnung")
                          }
                        >
                          <span className="nav-link-drop">
                            {t("apartment")}
                          </span>
                        </a>
                      </li>
                    </ul>
                    {/* : null } */}
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      to="/unser-team"
                      onClick={(event) => {
                        event.preventDefault();

                        // toogleMenu(); // Calls your function
                      }}
                    >
                      {t("aboutUs")}
                      <img src={dropdownmenu} className="vis" alt="Open Menu" />
                    </Link>
                    <ul className="nav drop-menu">
                      {/* <li className="nav-item">
                      <Link className="nav-link" to="/verkauf">
                        {t('propertySale')}
                      </Link>
                    </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/unser-team"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop"> {t("ourTeam")}</span>
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/ratgeber"
                          onClick={() => {toogleMenu(); clearFilters(); setPage(1)}}
                        >
                          <span className="nav-link-drop"> {t("guide")} </span>
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/service-garantie"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {" "}
                            {t("serviceGuarantee")}{" "}
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/partner"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {" "}
                            {t("partners")}{" "}
                          </span>
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/join-team"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {" "}
                            {t("bePartOfTeam")}{" "}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      to="/verkauf"
                      onClick={() => {
                        toogleMenu();
                        clearFilters();
                        setPage(1);
                      }}
                    >
                      {t("propertySale")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      to="/buildingBauen-auf-Mallorca"
                      onClick={() => {
                        toogleMenu();
                        clearFilters();
                        setPage(1);
                      }}
                    >
                      {t("BuildingMallorca")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/horizontal"
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      onClick={() => {
                        toogleMenu();
                        clearFilters();
                        setPage(1);
                      }}
                    >
                      {t("Calculator")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      to="/kontakt-immobilienmakler-mallorca"
                      onClick={() => {
                        toogleMenu();
                        clearFilters();
                        setPage(1);
                      }}
                    >
                      {t("contact")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={
                        i18n?.language.startsWith("es")
                          ? "nav-link"
                          : "headerSize nav-link"
                      }
                      to="/"
                      onClick={(event) => {
                        event.preventDefault();
                        // toogleMenu(); // Calls your function
                      }}
                    >
                      {t("More")}
                      <img src={dropdownmenu} className="vis" alt="Open Menu" />
                    </Link>
                    <ul className="nav drop-menu">
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/info-guide"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {t("Info Guide")}{" "}
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/video-touren"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {" "}
                            {t("Area Tours")}{" "}
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/presentation-of-properties"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {t("Property Presentations")}{" "}
                          </span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link"
                          to="/lifestyle_guide"
                          onClick={() => {
                            toogleMenu();
                            clearFilters();
                            setPage(1);
                          }}
                        >
                          <span className="nav-link-drop">
                            {t("lifestyleGuide")}{" "}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="navbar-toggler d-flex align-items-center"
              onClick={toogleMenu}
            >
              <button
                type="button"
                className="navbar-toggler mr-2 me-1 menu-button"
                // onClick={toogleMenu}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              </button>
              <small className="mt-1 d-inline ">MENU</small>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Header;
